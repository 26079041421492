<template>
<v-dialog v-model="dialog" width="800">
    <v-card>
      <v-card-title class="text-h5 bg-info d-flex justify-space-between" primary-title>
        <div class="d-flex align-center">
          <v-icon style="color: white" class="pr-3">
            mdi-information
          </v-icon>
          <span style="color: white">
            {{ title }}
          </span>
        </div>
          <v-btn icon color="transparent" @click="closeDialog" style="box-shadow: none">
              <v-icon color="white">mdi-close</v-icon>
          </v-btn>
      </v-card-title>
      <v-card-text>
        <p class="blockquote">
        {{ text }}
        </p>
        <strong v-if="highlightSection !== null"> {{ highlightSection }} </strong>
      </v-card-text>
      <v-card-actions>
          <v-spacer></v-spacer>
          <Button variant="flat" color="info" @click="closeDialog">
              Verstanden
          </Button>
      </v-card-actions>
    </v-card>
</v-dialog>
</template>
<script>
import Button from '@s/views/general_components/components/Button'

export default {
    name: "explanation-dialog",
    data() {
        return {
            dialog: false
        }
    },
    props: {
      title: {
        type: String,
        default: 'Definition'
      },
      text: {
        type: String,
        required: true
      },
      highlightSection: {
        type: String,
        default: null
      }
    },
    watch: {
      dialog(val) {
        val || this.closeDialog();
      }
    },
    methods: {
        open() {
            this.dialog = true;
        },
        closeDialog() {
          this.dialog = false;
        },
        confirm(){
          this.closeDialog();
        }
    },
    components: {
      Button
    }
}
</script>
