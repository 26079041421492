<template>
<v-dialog v-model="dialog" max-width="80vw">
    <v-card v-if="einrichtung !== null">
           <v-card-title class="text-h5 bg-primary d-flex justify-space-between" primary-title>
             <div class="d-flex align-center">
                <v-icon size="30" class="pr-3" style="color: white">mdi-information</v-icon>
                <span style="color: white">
                  {{ einrichtung.name }} -
                  <span v-if="einrichtung.aufnahmedatum !== null && einrichtung.aufnahmedatum.von !== null">
                      Aufnahmedatum: {{ transformDate(einrichtung.aufnahmedatum.von)}}
                  </span>
                </span>
            </div>
            <v-btn icon color="transparent" @click="closeDialog" style="box-shadow: none">
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-container fluid class="pa-4">
          <v-chip
            class="ma-2"
            color="primary"
            variant="outlined"
          >
            Träger: {{ resolveTraeger(einrichtung.traeger) }}
          </v-chip>

          <v-chip
            class="ma-2"
            color="primary"
            variant="outlined"
          >
            AGS: {{ einrichtung.ags }}
          </v-chip>

          <v-chip
            class="ma-2"
            :color="einrichtung.freiePlaetze < 0 ? 'error darken-2' : 'primary'"
            variant="outlined"
          >
            Freie Plätze: {{ einrichtung.freiePlaetze }}
          </v-chip>

          <v-chip
            class="ma-2"
            color="primary"
            variant="outlined"
          >
            Gesamt Plätze: {{ einrichtung.gesamtPlaetze }}
          </v-chip>

          <Tooltip top>
              <v-progress-linear
              class="mt-0"
                height="40"
                v-bind:color="einrichtung.freiePlaetze < 0 ? 'red' : 'green'"
                :model-value="((einrichtung.gesamtPlaetze - einrichtung.freiePlaetze) / einrichtung.gesamtPlaetze) * 100"
              >
            </v-progress-linear>
            <template #content>
              <span>Freie Plätze: {{einrichtung.freiePlaetze}} Gesamt Plätze: {{einrichtung.gesamtPlaetze}}</span>
            </template>
          </Tooltip>

          <br />

          <v-alert :value="true" type="info" variant="outlined" class="mb-4">
            <h3>Informationen </h3>
              <b class="ml-2">"{{ einrichtung.informationen}}"</b>
            
            <!--<h3 class="mt-2" v-if="einrichtung.betreuungsangebot">Beschreibung des Betreuungsangebots </h3>-->
              <div><b class="ml-2" v-if="einrichtung.betreuungsangebot">"{{ einrichtung.betreuungsangebot}}"</b></div>
          </v-alert>
          
          <BetreuungszeitenTable
            :betreuungszeiten="einrichtung.betreuungszeit"
            v-if="einrichtung.betreuungszeit">
          </BetreuungszeitenTable>

          <table style="width: 100%" class="pt-4">
            <tr>
                <th colspan="5">
                    <span class="text-h6 text-primary-darken-2">
                      <b>Ansprechpartner</b>
                    </span>
                </th>
            </tr>
            <tr>
                <th>Name</th>
                <th>Telefon</th>
                <th>Fax</th>
                <th>EMail</th>
            </tr>
            <tr v-for="(ansprechpartner, index) in einrichtung.ansprechpartner" :key="index">
                <td v-for="item in ['name', 'telefon', 'fax', 'email']" :key="item">
                  {{ ansprechpartner[item] }}
                </td>
            </tr>
          </table>

          <table style="width: 100%" class="pt-4">
            <tr>
                <th colspan="5">
                    <span class="text-h6 text-primary-darken-2">
                      <b>Adresse</b>
                    </span>
                </th>
            </tr>
            <tr>
                <th>Straße</th>
                <th>Hausnummer</th>
                <th>Postleitzahl</th>
                <th>Ort</th>
            </tr>
            <tr>
                <td v-for="item in ['strasse', 'hausnummer', 'postleitzahl', 'ort']" :key="item">
                  {{ einrichtung['adresse'][item] }}
                </td>
            </tr>
          </table>

          <table style="width: 100%" class="py-4">
            <tr>
                <th colspan="5">
                    <span class="text-h6 text-primary-darken-2">
                      <b>Sonstiges</b>
                    </span>
                </th>
            </tr>
            <tr>
                <th>Gruppen</th>
                <th>Teilahme an Portallösung</th>
                <th>Minimum Tage</th>
                <th>Minimum Wochenstunden</th>
            </tr>
            <tr>
                <td v-for="item in ['gruppen', 'teilnahmeAnPortalloesung', 'minimumTage', 'minimumWochenstunden']" :key="item">
                  {{ formatItem(einrichtung[item], item) }}
                </td>
            </tr>
          </table>

        </v-container>
    </v-card>
</v-dialog>
</template>
<script>
import BetreuungszeitenTable from './components/BetreuungszeitenTable.vue'
import Tooltip from '@s/views/general_components/components/Tooltip'
import Button from '@s/views/general_components/components/Button'
import { transformDate } from '@s/util/helper'

export default {
    name: "einrichtung-information-dialog",
    data() {
        return {
            traeger: this.$select('traeger.traeger as traeger'),
            dialog: false,
            einrichtungsarten: this.$select('general.einrichtungsarten as einrichtungsarten'),
            einrichtung: null,
            fieldsNotToRender: ['bildHexString', 'bildMimetype', 'id', 'location', 'betreuungszeit', 'bilder'],
            betreuungszeitHeader: []
        }
    },
    methods: {
        transformDate,
        open(einrichtung) {
            this.einrichtung = einrichtung
            if (this.betreuungszeitHeader.length === 0 && this.einrichtung.betreuungszeit) {
              this.betreuungszeitHeader.push({
                text: '',
                sortable: false
              });
                Object.keys(einrichtung.betreuungszeit).forEach(key => {
                    let firstLetterUpperCase = key.charAt(0).toUpperCase() + key.substr(1);
                    this.betreuungszeitHeader.push({
                        text: firstLetterUpperCase,
                        sortable: false
                    });
                })
            };
            this.dialog = true;
        },
        closeDialog() {
            this.dialog = false;
        },
        resolveTraeger(id){
          let index = this.traeger.findIndex(x => x.id === id);
          if (index >= 0) {
            return this.traeger[index].name
          }
          return '-'
        },
        formatItem(value, item) {
          if (item === 'teilnahmeAnPortalloesung') {
            return value ? 'JA' : 'NEIN';
          }
          return value;
        }
    },
    components: {
      BetreuungszeitenTable,
      Tooltip,
      Button
    }
}
</script>

<style lang="stylus" scoped>
    i
      font-weight: bold

    td, th
      border: 1px solid #dddddd
      text-align: left
      padding: 8px

    tr:nth-child(even)
      background-color: #dddddd

</style>
