<template>
<v-dialog v-model="dialog" width="600">
    <v-card>
        <v-card-title class="text-h5 bg-primary-lighten-1 d-flex justify-space-between" primary-title>
          <div class="d-flex align-center">
            <v-icon size="large" style="color: white" class="pr-3">
                mdi-plus
            </v-icon>
            <span style="color: white">
              Kita auswählen
          </span>
          </div>
            <v-btn color="transparent" style="box-shadow: none" size="large" icon @click="close">
                <v-icon color="white">
                    mdi-close
                </v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            v-model="model"
            :items="einrichtungenFiltered"
            item-title="name"
            item-value="id"
            persistent-hint
            prepend-icon="mdi-magnify"
            no-data-text="Keine Einrichtung mit diesem Namen gefunden"
            class="ma-5"
          />
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <Button
              raised
              color="primary"
              @click="confirm"
              :disabled="!model">
                An diese Kita zuteilen
            </Button>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>
<script>
import Button from '@s/views/general_components/components/Button'

export default {
    name: "add-kita-dialog",
    components: {
      Button
    },
    data() {
        return {
            dialog: false,
            einrichtungen: this.$select('bedarfsAnmeldung.einrichtungen as einrichtungen'),
            einrichtungenFiltered: [],
            model: null
        }
    },
    
    methods: {
      open(priorities) {
        this.dialog = true;
        if(priorities){
          let c = [];
          priorities.forEach(p => {
              c.push(p.id);
          })
          this.einrichtungenFiltered = this.einrichtungen.filter(x => !c.includes(x.id));
        };
      },
      close() {
          this.dialog = false;
      },
      confirm() {
          let index = this.einrichtungen.findIndex(x => x.id === this.model);
          if(index >= 0){
            this.$emit('kitaSelected', this.einrichtungen[index]);
          }
          this.close();
      }
    }
}
</script>
