<template>
    <v-dialog v-model="dialog" max-width="50vw">
        <v-card>
            <v-card-title class="bg-primary text-h5 d-flex justify-space-between" primary-title>
              <div class="align-center">
                <v-icon size="large" style="color: white" class="pr-3">
                    mdi-help-circle-outline
                </v-icon>
                <span style="color: white"> Erklärungsdialog - Zuteilung </span>
              </div>
                <Button large class="close-button" icon @click="close">
                    <v-icon color="white"> mdi-close </v-icon>
                </Button>
            </v-card-title>
            <v-container class="mt-4">
                <v-row row wrap justify-center>
                    <v-col xs12 md10>
                        <v-alert :value="true" type="info" variant="outlined">
                            Alle von Ihnen vorgenommenen Änderungen werden erst
                            aktiv und permanent gespeichert wenn Sie den
                            Speichern-Button drücken. Vorher sind alle
                            Änderungen nur lokal auf Ihrem Rechner vorhanden.
                            Die einizge Ausnahme bildet die Archivierung einer
                            Anmeldung. Diese wird sofort archiviert.
                        </v-alert>
                    </v-col>
                </v-row>
                <br />
                <br />
                <v-row row wrap justify-center>
                    <v-col xs12 md10>
                        <table style="width: 100%">
                            <tr>
                                <th colspan="6">
                                    <span class="text-h6 text-primary-darken-2">
                                        <b>Merkmale</b>
                                    </span>
                                </th>
                            </tr>
                            <tr>
                                <th>Merkmal</th>
                                <th>Bedeutung</th>
                            </tr>
                            <tr>
                                <td>
                                    <span class="text-primary"><b>a</b></span>
                                </td>
                                <td>
                                    Ein Vertreter des Kindes ist
                                    <b>alleinerziehend</b>.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="text-primary"><b>ad</b></span>
                                </td>
                                <td>
                                    Ein <b>alternatives Aufnahmedatum</b> wurde
                                    gesetzt.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="text-primary"><b>b</b></span>
                                </td>
                                <td>
                                    Eine <b>Bemerkung</b> wurde während des
                                    Zuteilungsprozesses hinzugefügt.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="text-primary"><b>i</b></span>
                                </td>
                                <td>Es liegt <b>integrationsbedarf</b> vor.</td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="text-primary"><b>gk</b></span>
                                </td>
                                <td>Das Kind hat <b>Geschwisterkinder</b>.</td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="text-primary"><b>u</b></span>
                                </td>
                                <td>
                                    Es handelt sich um eine
                                    <b>unterjährige</b> Anmeldung.
                                </td>
                            </tr>
                                                        <tr>
                                <td>
                                    <span class="text-primary"><b>BT</b></span>
                                </td>
                                <td>
                                    <b>Beide</b> Vertreter sind
                                    <b>berufstätig</b>.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="text-primary"><b>bt</b></span>
                                </td>
                                <td>Ein Vertreter ist <b>berufstätig</b>.</td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="text-primary"><b>nh</b></span>
                                </td>
                                <td>
                                    Es liegt ein <b>neuer Hauptwohnsitz</b> vor.
                                </td>
                            </tr>
                        </table>
                    </v-col>
                </v-row>
                <br />
                <br />
                <v-row row wrap justify-center>
                    <v-col xs12 md10>
                        <table style="width: 100%">
                            <tr>
                                <th colspan="6">
                                    <span class="text-6 text-primary-darken-2">
                                        <b>Farben</b>
                                    </span>
                                </th>
                            </tr>
                            <tr>
                                <th>Hintergrundfarbe</th>
                                <th>Bedeutung</th>
                            </tr>
                            <tr>
                                <td
                                    style="
                                        background-color: rgb(0, 107, 3, 0.175);
                                    "
                                >
                                    Bedarfsanmeldung
                                </td>
                                <td>
                                    Die Anmeldung wurde einer Einrichtung
                                    <b>zugeteilt</b>.
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style="
                                        background-color: rgb(142, 0, 0, 0.4);
                                    "
                                >
                                    Bedarfsanmeldung
                                </td>
                                <td>
                                    Die Bedarfsanmeldung wurde
                                    <b>zurückgestellt</b>.
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style="
                                        background-color: rgb(255, 255, 0, 0.2);
                                    "
                                >
                                    Bedarfsanmeldung
                                </td>
                                <td>
                                    <b>Alle</b> priorisierten Einrichtungen
                                    dieser Anmeldung haben <b>abgelehnt</b>.
                                </td>
                            </tr>
                        </table>
                    </v-col>
                </v-row>
                <br />
                <br />
                <v-row row wrap justify-center>
                    <v-col xs12 md10>
                        <table style="width: 100%">
                            <tr>
                                <th colspan="6">
                                    <span class="text-h6 text-primary-darken-2">
                                        <b>Aktionen</b>
                                    </span>
                                </th>
                            </tr>
                            <tr>
                                <th>Aktion</th>
                                <th>Auswirkung</th>
                            </tr>
                            <tr>
                                <td>
                                    <span class="text-primary">
                                        <b>Zurückstellung</b>
                                    </span>
                                </td>
                                <td>
                                    Die Anmeldung wird <b>zurückgestellt</b>. Es
                                    sollte nur aus bestimmten Gründen
                                    zurückgestellt werden. Z.B.: Die Eltern
                                    haben den Bedarf zurückgezogen.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="text-primary">
                                        <b>Bemerkung</b>
                                    </span>
                                </td>
                                <td>
                                    Eine <b>Bemerkung verfassen</b>. Diese
                                    Bemerkung wird zusammen mit der
                                    Bedarfsanmeldung gespeichert, wird den
                                    anderen Einrichtungen angezeigt und sollte
                                    beim Zuteilungsprozess beachtet werden. Sie
                                    wird nicht nacht außen kommuniziert.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="text-primary">
                                        <b>Archivierung</b>
                                    </span>
                                </td>
                                <td>
                                    Bei der Archivierung wird die
                                    Bedarfsanmeldung in ein spezielles
                                    <b>Archiv verschoben</b>
                                    und somit aus dem Zuteilungsprozess
                                    entfernt, nutzen Sie die Archivierung, um
                                    die Anmeldungen der vergangenen Saison zu
                                    archivieren oder um eine Bedarfsanmeldung
                                    aus der aktuellen Saison zu entfernen und
                                    somit eine ggf. erneute Anmeldung für das
                                    betroffene Kind möglich zu machen.
                                </td>
                            </tr>
                        </table>
                    </v-col>
                </v-row>
                <br />
                <br />
                <v-row row wrap justify-center>
                    <v-col xs12 md10>
                        <table style="width: 100%">
                            <tr>
                                <th colspan="6">
                                    <span class="text-h6 text-primary-darken-2">
                                        <b>Mögliche Nebeneffekte</b>
                                    </span>
                                </th>
                            </tr>
                            <tr>
                                <th>Aktion</th>
                                <th>Nebeneffekt</th>
                            </tr>
                            <tr>
                                <td>
                                    <span class="text-primary">
                                        <b>Zuteilung</b>
                                    </span>
                                </td>
                                <td>
                                    Sollte eine bereits
                                    <b> zugeteilte und benachrichtigte</b>
                                    Anmeldung neu zugeteilt werden, so wird ihr
                                    <b>Benachrichtigungsstatus</b> wieder auf
                                    <b>offen</b> gesetzt.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="text-primary">
                                        <b>Zuteilung</b>
                                    </span>
                                </td>
                                <td>
                                    Beim Speichern der Zuteilungen werden die
                                    <b>freien Plätze</b>
                                    der Einrichtungen dementsprechend
                                    verringert.
                                </td>
                            </tr>
                        </table>
                    </v-col>
                </v-row>
                <br />
                <br />
                <v-row row wrap justify-center>
                    <v-col xs12 md10>
                        <table style="width: 100%">
                            <tr>
                                <th colspan="6">
                                    <span class="text-h6 text-primary-darken-2">
                                        <b>Interne Priorität</b>
                                    </span>
                                </th>
                            </tr>
                            <tr>
                                <th class="text-center">
                                    <v-icon color="success">mdi-numeric-1-box</v-icon>
                                </th>
                                <td>
                                    <b>Sehr niedrige Dringlichkeit</b> für eine
                                    Bearbeitung der Anmeldung
                                </td>
                            </tr>
                            <tr>
                                <th class="text-center">
                                    <v-icon color="success">mdi-numeric-2-box</v-icon>
                                </th>
                                <td>
                                    <b>Niedrige Dringlichkeit</b> für eine
                                    Bearbeitung der Anmeldung
                                </td>
                            </tr>
                            <tr>
                                <th class="text-center">
                                    <v-icon color="warning">mdi-numeric-3-box</v-icon>
                                </th>
                                <td>
                                    <b>Mittlere Dringlichkeit</b> für eine
                                    Bearbeitung der Anmeldung
                                </td>
                            </tr>
                            <tr>
                                <th class="text-center">
                                    <v-icon color="error">mdi-numeric-4-box</v-icon>
                                </th>
                                <td>
                                    <b>Hohe Dringlichkeit</b> für eine
                                    Bearbeitung der Anmeldung
                                </td>
                            </tr>
                            <tr>
                                <th class="text-center">
                                    <v-icon color="error-darken-2"
                                        >mdi-numeric-5-box</v-icon
                                    >
                                </th>
                                <td>
                                    <b>Sehr hohe Dringlichkeit</b> für eine
                                    Bearbeitung der Anmeldung
                                </td>
                            </tr>
                        </table>
                    </v-col>
                </v-row>
            </v-container>
            <v-card-actions>
                <v-spacer></v-spacer>
                <Button variant="flat" color="primary" @click="close">
                    Schließen
                </Button>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import Button from '@s/views/general_components/components/Button';

export default {
    name: 'helper-dialog',
    components: {
        Button,
    },
    data() {
        return {
            dialog: false,
        };
    },
    methods: {
        open() {
            this.dialog = true;
        },
        close() {
            this.dialog = false;
        },
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
    },
};
</script>
<style lang="stylus" scoped>
i
  font-weight: bold

td, th
  border: 1px solid #dddddd
  text-align: left
  padding: 8px

tr:nth-child(even)
  background-color: #dddddd
</style>
