<template>

<v-row class="mb-5" style="justify-content: center">

    <v-col cols="6" class="pt-4">
      <v-app-bar color="primary" class="relative-toolbar">
        <v-app-bar-nav-icon>
            <v-icon color="white">mdi-new-box</v-icon>
        </v-app-bar-nav-icon>
        <v-app-bar-title class="text-white">Neuigkeiten</v-app-bar-title>
      </v-app-bar>

      <v-expansion-panels>

        <v-expansion-panel 
          expand
          v-for="(item, index) in changes"
          :key="index">

          <v-expansion-panel-title>

            <span class="text-primary" >
                <span :class="item.major || item.is_new ? 'font-weight-bold' : ''">{{ item.title }}</span>

                <span v-if="item.major" class="font-italic">- Bedeutende Änderungen</span>

                <span v-if="item.is_new" class="font-italic">- Neuste Version</span>
            
            </span> 
  
          </v-expansion-panel-title>

          <v-expansion-panel-text>

            <h4 class="text-left text-primary">{{ item.published }}</h4>

            <div v-for="(note, i) in item.items" :key="i">
              <v-divider></v-divider>
              <v-row row wrap class="my-0">
                  <v-col cols="3">
                    <div>
                        <h4
                          class="pointer text-primary text-left"
                          @click="navigate(note.action)">
                            {{ note.cat }}
                        </h4>
                    </div>
                  </v-col>
                  <v-col>
                    <div align="left" class="mx-2">
                        <b>&bull;</b><i><span class="pl-2">{{ note.title}}</span></i>
                        <Tooltip top v-if="note.fix">
                          <v-icon
                            class="pointer"
                            color="primary">
                              mdi-bug
                          </v-icon>
                          <template #content>
                            <span>Fehlerbehebung</span>
                          </template>
                      </Tooltip>
                    </div>
                  </v-col>
              </v-row>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
</v-row>

</template>
<script>
import changes from './changes.js'
import Tooltip from '@s/views/general_components/components/Tooltip'

export default {
    name: "patch-notes",
    components: {
      Tooltip
    },
    data() {
        return {
          permissions: this.$select('user.permissions as permissions'),
          role: this.$select('user.role as role'),
          adminRoutes: ['einrichtungen-bilder', 'einrichtungsarten', 'saisonparameter', 'eltern-benachrichtigungen', 'change-log'],
          changes: changes.changes
        }
    },
    methods: {
        navigate(route) {

          // Enforce the permissions
          if(route !== null){
            if(this.adminRoutes.includes(route)){
              switch (route) {
                case 'einrichtungen-bilder':
                  if(this.permissions['Einrichtung.images'].includes('lesen / anzeigen')){
                    this.$router.replace(route);
                  }
                  break;
                case 'einrichtungsarten':
                  if(this.permissions['Einrichtungsart'].includes('lesen / anzeigen')){
                    this.$router.replace(route);
                  }
                  break;
                case 'saisonparameter':
                  if(this.permissions['Saisonparameter'].includes('lesen / anzeigen')){
                    this.$router.replace(route);
                  }
                  break;
                case 'eltern-benachrichtigen':
                  if(this.permissions['Postkorbnachricht.send'].includes('lesen / anzeigen')){
                    this.$router.replace(route);
                  }
                case 'change-log':
                  if(this.role === 'KV' || this.role === 'Superadmin'){
                    this.$router.replace(route);
                  }
                case 'kennzahlen':
                  if(this.role === 'Superadmin'){
                    this.$router.replace(route);
                  }
                  break;
              }
            } else {
              this.$router.replace(route);
            }
          }
        }
    }
}
</script>

<style lang="stylus" scoped>
</style>
