import {
        noop,
        calculateAge,
        findNameFromID,
        transformDate,
        calculateAddedHours,
        dateToSeconds,
        compareLexicographical
} from '../../util/helper'

import {
  GETepicFactory,
  PUTepicFactory,
  mergeEpicFactory,
  POSTepicFactory
} from '../../util/epicFactories';

const prefix = `STATISTICS`;
export const FETCH_EINRICHTUNGEN = `${prefix}/FETCH_EINRICHTUNGEN`;
export const FETCH_REGISTRATIONS = `${prefix}/FETCH_REGISTRATIONS`;
export const CLEAR_STORE = `${prefix}/CLEAR_STORE`;
export const CALCULATE_CAPACITY = `${prefix}/CALCULATE_CAPACITY`;
export const CALCULATE_CARETIMES = `${prefix}/CALCULATE_CARETIMES`;
export const CALCULATE_PRIORITIES = `${prefix}/CALCULATE_PRIORITIES`;
export const CALCULATE_CARETIMES_HOURS = `${prefix}/CALCULATE_CARETIMES_HOURS`;

export const fetchRegistrationsEpic = GETepicFactory(`${prefix}/FETCH_REGISTRATIONS`, {
    url: (action) => `/api/v1/kita/admin/bedarfsanmeldungen?page=0&size=2147483647&sort=ERZEUGUNGSDATUM&dir=DESC`      
})
export const fetchEinrichtungenEpic = GETepicFactory(`${prefix}/FETCH_EINRICHTUNGEN`, {
    url: (action) => `/api/v1/kita/einrichtungen?page=0&size=2147483647&sort=NAME&dir=ASC`
})


export const fetchRegistrations = (success = noop, error = noop) => fetchRegistrationsEpic.run({},{success, error});
export const fetchEinrichtungen = (success = noop, error = noop) => fetchEinrichtungenEpic.run({},{success, error});
export const clearStore = () => ({
    type: CLEAR_STORE
});
export const calculateCapacity = (facilities) => dispatch => {
    dispatch({
        type: CALCULATE_CAPACITY,
        payload: {
            facilities: facilities
        }
    });
    return Promise.resolve();
}
export const calculateCareTimes = (facilities) => dispatch => {
    dispatch({
        type: CALCULATE_CARETIMES,
        payload: {
            facilities: facilities
        }
    });
    return Promise.resolve();
}
export const calculatePriorities = (facilities) => dispatch => {
    dispatch({
        type: CALCULATE_PRIORITIES,
        payload: {
            facilities: facilities
        }
    });
    return Promise.resolve();
}
export const calculateCareTimesHours = (facilities) => dispatch => {
    dispatch({
        type: CALCULATE_CARETIMES_HOURS,
        payload: {
            facilities: facilities
        }
    });
    return Promise.resolve();
}


const ACTION_HANDLERS = {

  ...fetchRegistrationsEpic.ACTION_HANDLERS,
  [fetchRegistrationsEpic.START]: (state, action) => {
    return {
      ...initialState,
      einrichtungen: state.einrichtungen,
      isFetching: true,
      fetched: false
    }
  },
  [fetchRegistrationsEpic.SUCCESS]: (state, action) => {

    const newState = action.payload.response;

    let einrichtungenOverview = [];
    state.einrichtungen.forEach(einrichtung => {
        let p = {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0
        }
        einrichtungenOverview.push({
            id: einrichtung.id,
            name: einrichtung.name,
            selected: p
        });
    });



    let overview = {
      numberOfChildren: 0,

      // 0 years old, 1, 2, 3, 4, 5, 6, 7, 8 - 12
      ages: [0, 0, 0, 0, 0, 0, 0, 0, 0],

      // MAENNLICH, WEIBLICH, NOCH_NICHT_BEKANNT, DIVERS, NULL
      genders: [0, 0, 0, 0, 0],
      singleParents: 0,
      childrenWithIntegrationsbedarf: 0,
      childrenWithSiblings: 0,
      lateRegistrations: 0,
      assignedRegistrations: 0,
      refusedRegistrations: 0,
      placesDict: {},
      placesAmount: {},
    }


    for (let i = 0; i < newState.length; ++i) {
        if (newState[i].aufnahmedatum !== null && newState[i].aufnahmedatum.von !== null) {
            let date = new Date(newState[i].aufnahmedatum.von);
            let year = new Date().getFullYear();
            let month = new Date().getMonth();
            if(month <= 7){
              --year;
            }
            let seasonDate = new Date(`${year}-09-01`);
            let seasonDateSucc = new Date(`${++year}-08-31`);
            if((date.getTime() >= seasonDate.getTime()) && (date.getTime() <= seasonDateSucc.getTime())){
              overview.lateRegistrations++;
            }
        }
        newState[i].kindesdaten.forEach(child => {
            if (child.integrationsbedarf !== null && child.integrationsbedarf.length > 0) {
                overview.childrenWithIntegrationsbedarf++;

            }
            let gender = "Kein Geschlecht angegeben";
            if (child.geschlecht !== null) {
                gender = child.geschlecht;
            };
            switch(gender){
              case 'MAENNLICH':
                overview.genders[0]++;
                break;
              case 'WEIBLICH':
                overview.genders[1]++;
                break;
              case 'NOCH_NICHT_BEKANNT':
                overview.genders[2]++;
                break;
              case 'DIVERS':
                overview.genders[3]++;
                break;
              case 'Kein Geschlecht angegeben':
                overview.genders[4]++;
                break;
            }

            let wohnort = child.wohnort;
            let found = false;

            // Resolving wohnort, child.wohnort lists the name of the parent
            // the child is living with
            if (wohnort !== null) {
                newState[i].vertreter.forEach(x => {
                    let vName = `${x.vorname} ${x.name}`.toUpperCase().trim();
                    if (vName === wohnort.toUpperCase().trim()) {
                        if (x.hauptwohnsitz.ort) {
                            found = true;
                            overview.placesDict[x.hauptwohnsitz.postleitzahl] = x.hauptwohnsitz.ort;
                            if(Object.keys(overview.placesAmount).includes(x.hauptwohnsitz.postleitzahl)){
                              overview.placesAmount[x.hauptwohnsitz.postleitzahl]++;
                            } else {
                              overview.placesAmount[x.hauptwohnsitz.postleitzahl] = 1;
                            }
                        }
                    };
                });
            }
        })

        if (newState[i].zugeteilteEinrichtung !== null) {
            overview.assignedRegistrations += newState[i].kindesdaten.length;
        } else {
            if (newState[i].status === "ZURUECKGESTELLT") {
                overview.refusedRegistrations += newState[i].kindesdaten.length;
            }
        }
        let singleParentFound = false;
        newState[i].vertreter.forEach(x => {


            if(x.alleinerziehend){
              if(!singleParentFound){
                singleParentFound = true;
                overview.singleParents++;
              }
            }
        });

        if(newState[i].geschwister !== null && newState[i].geschwister.length > 0){
          overview.childrenWithSiblings++;
        }

        newState[i].kindesdaten.forEach(child => {
            overview.numberOfChildren++;
            let age = null;
            if (child.geburtsdatum) {
                age = calculateAge(child.geburtsdatum, new Date);
                if (age.year >= 0 && age.year <= 7) {
                    overview.ages[age.year]++;
                }
                if (age.year >= 8 && age.year <= 12) {
                    overview.ages[8]++;
                }
            }


        });
        let c = 1;
        newState[i].priorities.forEach(kita => {
            let index = state.einrichtungen.findIndex(x => x.id === kita.id);
            if (index >= 0) {
                let i = einrichtungenOverview.findIndex(x => x.id === kita.id);
                if (i >= 0) {
                    einrichtungenOverview[i].selected[c]++
                }
            }
            c++;
        });


    }


    return {
        ...state,
        registrations: newState,
        isFetching: false,
        overview: {
            ...overview,
            einrichtungen: einrichtungenOverview,
        }
    }

  },
  ...fetchEinrichtungenEpic.ACTION_HANDLERS,
  [fetchEinrichtungenEpic.SUCCESS]: (state, action) => {
      action.payload.response.sort(compareLexicographical);
      return {
        ...state,
        einrichtungen: action.payload.response,
        isFetching: true
      }
  },
  [CLEAR_STORE]: (state, action) => {
      return {
          ...initialState
      }
  },
  [CALCULATE_CAPACITY]: (state, action) => {

    let facilities = action.payload.facilities;
    let einrichtungen = state.einrichtungen;
    let einrichtungenSelected = [];

    facilities.forEach(id => {
      let index = einrichtungen.findIndex(x => x.id === id);
      if(index >= 0){
        einrichtungenSelected.push(einrichtungen[index]);
      }
    })

    let openSeats = [];
    let openSeatsColor = [];
    let closedSeats = [];
    let labelNames = [];
    let labelDict = {};

    einrichtungenSelected.forEach(einrichtung => {
      labelNames.push(einrichtung.name);
      if(einrichtung.freiePlaetze !== null){
        openSeats.push(einrichtung.freiePlaetze);
      } else {
        openSeats.push(0);
      }
      if(einrichtung.freiePlaetze !== null && einrichtung.gesamtPlaetze !== null){
        closedSeats.push(einrichtung.gesamtPlaetze - einrichtung.freiePlaetze);
        labelDict[einrichtung.name] = `${einrichtung.name} - ${einrichtung.gesamtPlaetze} Gesamt Plätze`;
        if(einrichtung.freiePlaetze < 0){
          openSeatsColor.push('rgba(142, 0, 0, 0.4)');
        } else {
          openSeatsColor.push('rgba(51, 153, 255, 0.4)');
        }
      } else {
        closedSeats.push(0);
        labelDict[einrichtung.name] = einrichtung.name;
        openSeatsColor.push('rgba(142, 0, 0, 0.4)');
      }
    })
      return {
        ...state,
        capacity: {
          labelNames: labelNames,
          openSeats: openSeats,
          openSeatsColor: openSeatsColor,
          closedSeats: closedSeats,
          labelDict: labelDict
        }
      }
  },
  [CALCULATE_CARETIMES]: (state, action) => {

    let facilities = action.payload.facilities;
    let einrichtungen = state.einrichtungen;
    let einrichtungenSelected = [];

    facilities.forEach(id => {
      let index = einrichtungen.findIndex(x => x.id === id);
      if(index >= 0){
        einrichtungenSelected.push(einrichtungen[index]);
      }
    })

    let registrations = state.registrations;

    let labelNames = [];
    let monday = [];
    let tuesday = [];
    let wednesday = [];
    let thursday = [];
    let friday = [];
    einrichtungenSelected.forEach(facility => {
      labelNames.push(facility.name);
      monday.push(0);
      tuesday.push(0);
      wednesday.push(0);
      thursday.push(0);
      friday.push(0);
      let latestIndex = monday.length - 1;
      registrations.forEach(registration => {
        if(registration.zugeteilteEinrichtung === facility.id){
          if(registration.betreuungszeit !== null){
            let dayIndex = 0;
            Object.keys(registration.betreuungszeit).forEach(day => {
              if(registration.betreuungszeit[day] !== null){
                if(registration.betreuungszeit[day].von !== null && registration.betreuungszeit[day].von !== '' &&
                  registration.betreuungszeit[day].bis !== null && registration.betreuungszeit[day].bis !== ''){
                    const hourStart = registration.betreuungszeit[day].von.substring(0,2);
                    const hourEnd = registration.betreuungszeit[day].bis.substring(0,2);
                    const minutesStart = registration.betreuungszeit[day].von.substring(3,5);
                    const minutesEnd = registration.betreuungszeit[day].bis.substring(3,5);
                    let careTime = ((hourEnd - hourStart) * 60) + (minutesEnd - minutesStart)
                    careTime = careTime / 60;
                    if(careTime > 0 && careTime < 0.5){
                      careTime = 1;
                    } else {
                      careTime = Math.floor(careTime);
                    }
                    switch (dayIndex) {
                      case 0:
                        monday[latestIndex] = monday[latestIndex] + careTime
                        break;
                      case 1:
                        tuesday[latestIndex] = tuesday[latestIndex] + careTime
                        break;
                      case 2:
                        wednesday[latestIndex] = wednesday[latestIndex] + careTime
                        break;
                      case 3:
                        thursday[latestIndex] = thursday[latestIndex] + careTime
                        break;
                      case 4:
                        friday[latestIndex] = friday[latestIndex] + careTime
                          break;
                      default:

                    }
                  }
              }
              dayIndex++;
            })
          }
        }
      })
    })
    return {
        ...state,
        careTimes: {
          labelNames: labelNames,
          monday: monday,
          tuesday: tuesday,
          wednesday: wednesday,
          thursday: thursday,
          friday: friday
        }
    }
  },
  [CALCULATE_PRIORITIES]: (state, action) => {

    let facilities = action.payload.facilities;
    let einrichtungen = state.einrichtungen;
    let einrichtungenSelected = [];

    facilities.forEach(id => {
      let index = einrichtungen.findIndex(x => x.id === id);
      if(index >= 0){
        einrichtungenSelected.push(einrichtungen[index]);
      }
    })

    let registrations = state.registrations;

    let rows = [];
    einrichtungenSelected.forEach(facility => {

      let row = {
        name: '',
        amount_prio_1: 0,
        amount_prio_2: 0,
        amount_prio_3: 0
      };
      row.name = facility.name;

      let p = [0, 0, 0];
      registrations.forEach(registration => {
        let pIndex = 0;
        registration.priorities.forEach(priority => {
          if(priority.id === facility.id){
            p[pIndex]++;
          }
          pIndex++;
        })
      })
      row.amount_prio_1 = p[0];
      row.amount_prio_2 = p[1];
      row.amount_prio_3 = p[2];
      rows.push(row)
    })

    return {
        ...state,
        priorities: {
          rows: rows
        }
    }
  },
  [CALCULATE_CARETIMES_HOURS]: (state, action) => {

    let facilities = action.payload.facilities;
    let einrichtungen = state.einrichtungen;
    let einrichtungenSelected = [];

    facilities.forEach(id => {
      let index = einrichtungen.findIndex(x => x.id === id);
      if(index >= 0){
        einrichtungenSelected.push(einrichtungen[index]);
      }
    })

    let registrations = state.registrations;


    let d = [0,0,0,0,0,0,0,0,0,0,0,0];
    let labelNames = [];
    let monday = {
      0: [...d], 1: [...d], 2: [...d], 3: [...d], 4: [...d], 5: [...d], 6: [...d],
      7: [...d], 8: [...d]
    };
    let tuesday = {
      0: [...d], 1: [...d], 2: [...d], 3: [...d], 4: [...d], 5: [...d], 6: [...d],
      7: [...d], 8: [...d]
    };
    let wednesday = {
      0: [...d], 1: [...d], 2: [...d], 3: [...d], 4: [...d], 5: [...d], 6: [...d],
      7: [...d], 8: [...d]
    };
    let thursday = {
      0: [...d], 1: [...d], 2: [...d], 3: [...d], 4: [...d], 5: [...d], 6: [...d],
      7: [...d], 8: [...d]
    };
    let friday = {
      0: [...d], 1: [...d], 2: [...d], 3: [...d], 4: [...d], 5: [...d], 6: [...d],
      7: [...d], 8: [...d]
    };
    einrichtungenSelected.forEach(facility => {
      registrations.forEach(registration => {
        if(registration.zugeteilteEinrichtung === facility.id){
          if(registration.betreuungszeit !== null){
            let dayIndex = 0;
            Object.keys(registration.betreuungszeit).forEach(day => {
              if(registration.betreuungszeit[day] !== null){
                if(registration.betreuungszeit[day].von !== null && registration.betreuungszeit[day].von !== '' &&
                  registration.betreuungszeit[day].bis !== null && registration.betreuungszeit[day].bis !== ''){

                    let targetDate = new Date();
                    if(registration.aufnahmedatum !== null && registration.aufnahmedatum.von !== null){
                      targetDate = registration.aufnahmedatum.von;
                    }
                    let age = calculateAge(registration.kindesdaten[0].geburtsdatum, targetDate);
                    if(age !== '-' && age.year !== null){

                      const hourStart = registration.betreuungszeit[day].von.substring(0,2);
                      const hourEnd = registration.betreuungszeit[day].bis.substring(0,2);
                      let range = [];
                      for(let i = hourStart; i <= hourEnd; ++i){
                        range.push(i - 6);
                      }

                      switch (dayIndex) {
                        case 0:
                          range.forEach(x => {
                            if(age.year < 8){
                              return monday[age.year][x]++;
                            } else {
                              return monday[8][x]++;
                            }
                          });
                          break;
                        case 1:
                          range.forEach(x => {
                            if(age.year < 8){
                              return tuesday[age.year][x]++;
                            } else {
                              return tuesday[8][x]++;
                            }
                          });
                          break;
                        case 2:
                          range.forEach(x => {
                            if(age.year < 8){
                              return wednesday[age.year][x]++;
                            } else {
                              return wednesday[8][x]++;
                            }
                          });
                          break;
                        case 3:
                          range.forEach(x => {
                            if(age.year < 8){
                              return thursday[age.year][x]++;
                            } else {
                              return thursday[8][x]++;
                            }
                          });
                          break;
                        case 4:
                          range.forEach(x => {
                            if(age.year < 8){
                              return friday[age.year][x]++;
                            } else {
                              return friday[8][x]++;
                            }
                          });
                            break;
                        default:

                      }
                    }
                  }
              }
              dayIndex++;
            })
          }
        }
      })
    })
    return {
        ...state,
        careTimesHours: {
          monday: monday,
          tuesday: tuesday,
          wednesday: wednesday,
          thursday: thursday,
          friday: friday
        }
    }
  },

}

export const actions = {
    fetchRegistrations,
    fetchEinrichtungen,
    clearStore,
    calculateCapacity,
    calculateCareTimes,
    calculatePriorities,
    calculateCareTimesHours
};

export const epics = [
  fetchRegistrationsEpic.epic,
  fetchEinrichtungenEpic.epic
]

const initialState = {
    einrichtungen: [],
    registrations: [],
    overview: [],
    isFetching: false,
    fetched: false,
    isSending: false,
    capacity: {
      labelNames: [],
      openSeats: [],
      closedSeats: [],
      labelDict: {},
      openSeatsColor: []
    },
    careTimes: {
      labelNames: [],
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: []
    },
    priorities: {
      labelNames: [],
      prio1: [],
      prio2: [],
      prio3: []
    },
    careTimesHours: {
      labelNames: [],
      monday: {},
      tuesday: {},
      wednesday: {},
      thursday: {},
      friday: {}
    },
};

export function reducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}

export default reducer;
