export default {
  changes: [
  {
    active: false,
    title: 'v3.0.2',
    published: 'Jun-2024',
    major: true,
    is_new: true,
    items: [
      {
        title: `Anzeigeprobleme beim CSV Export für Excel wurden behoben.`,
        cat: 'Export',
        action: null,
        fix: false
      },
      {
        title: `Die gesamte Oberfläche der Anwendung wurde überarbeitet und ist nun leichter und übersichtlicher zu bedienen.`,
        cat: 'Allgemein',
        action: null,
        fix: false
      },
      {
        title: `Aktualisierte Icons wurden implementiert.`,
        cat: 'Allgemein',
        action: null,
        fix: false
      },
      {
        title: `Der Kontrast wurde verbessert.`,
        cat: 'Allgemein',
        action: null,
        fix: false
      },
      {
        title: `Es gibt ein neues Grid-System, sodass sich Abstände verändert haben.`,
        cat: 'Allgemein',
        action: null,
        fix: false
      },
      {
        title: `Das Aussehen der Input- und Selektionsfelder wurde verändert.`,
        cat: 'Allgemein',
        action: null,
        fix: false
      },
      {
      title: `Das Format der Datumsanzeige wurde einheitlich auf DD.MM.YYYY umgestellt.`,
      cat: 'Allgemein',
      action: null,
      fix: false
      }
    ]
  },
  {
    active: false,
    title: 'v2.1.0',
    published: 'Aug-2023',
    major: false,
    is_new: false,
    items: [
      {
        title: `Ein neues Logo wurde eingeführt`,
        cat: 'Allgemein',
        action: null,
        fix: false
      },
      {
        title: `Der Detaildialog für die Ansicht einer Bedarfsmeldung wurde überarbeitet`,
        cat: 'Zuteilung',
        action: 'kita-bedarfsanmeldungen',
        fix: false
      },
      {
        title: `Ein neues Merkmal für den Hauptwohnsitz wurde eingeführt`,
        cat: 'Zuteilung',
        action: 'kita-bedarfsanmeldungen',
        fix: false
      },
      {
        title: `Mehreren Einrichtungsarten für eine Bedarfanmeldung sind jetzt möglich`,
        cat: 'Zuteilung',
        action: 'kita-bedarfsanmeldungen',
        fix: false
      }
    ]
  },
  {
    active: false,
    title: 'v2.0.2',
    published: 'Feb-2023',
    major: false,
    is_new: false,
    items: [
      {
        title: `Es ist nun möglich nach dem alternativen Aufnahmedatum zu sortieren.`,
        cat: 'Benachrichtigung',
        action: 'eltern-benachrichtigungen',
        fix: false
      },
      {
        title: `Es wurde ein Fehler beim CSV Export behoben.`,
        cat: 'Export',
        action: 'zugeteilte-bedarfsanmeldungen-export',
        fix: true
      }
    ]
  },
  {
    active: false,
    title: 'v2.0.0',
    published: 'Aug-2022',
    major: false,
    is_new: false,
    items: [
      {
        title: `Die Bibliotheken der grafischen Benutzeroberfläche wurden aktualisiert. Einige grafische Elemente haben sich dadurch verändert.`,
        cat: 'Allgemein',
        action: null,
        fix: false
      }
    ]
  },
  {
    active: false,
    title: 'v1.7.0',
    published: 'Okt-2021',
    major: true,
    is_new: false,
    items: [
      {
        title: `Die grafische Benutzeroberfläche wurde an einigen Stellen überarbeitet.`,
        cat: 'Allgemein',
        action: null,
        fix: false
      }
    ]
  },
  {
    active: false,
    title: 'v1.6.8',
    published: 'Sep-2021',
    major: false,
    is_new: false,
    items: [
      {
        title: `Der Change Log und dessen Filtermöglichkeiten wurden überarbeitet.`,
        cat: 'Change Log',
        action: 'change-log',
        fix: false
      },
      {
        title: `Es ist nun möglich Kennzahlen auszulesen.`,
        cat: 'Kennzahlen',
        action: 'kennzahlen',
        fix: false
      }
    ]
  },
  {
    active: false,
    title: 'v1.6.7',
    published: 'Mai-2021',
    major: false,
    is_new: false,
    items: [
      {
        title: `Kindernamen werden jetzt in der gesamten Anwendung mit Nachname Vorname angezeigt. (Früher Vorname Nachmane)`,
        cat: 'Allgemein',
        action: null,
        fix: false
      },
      {
        title: `Bestätigung wurde in Rücklauf umbenannt.`,
        cat: 'Rücklauf',
        action: 'bestaetigen',
        fix: false
      },
      {
        title: `Ein Hilfsdialog wurde hinzugefügt`,
        cat: 'Rücklauf',
        action: 'bestaetigen',
        fix: false
      },
      {
        title: `Die Handlung der Einrichtung wird jetzt direkt in der Zuteilung angezeigt`,
        cat: 'Zuteilung',
        action: 'kita-bedarfsanmeldungen',
        fix: false
      },
      {
        title: `Eine Filterung nach dem Kriterium 'Sie sind mit der Zuteilung dran' zeigt nun keine zurückgestellten Anmeldungen an`,
        cat: 'Zuteilung',
        action: 'kita-bedarfsanmeldungen',
        fix: false
      },
    ]
  },
  {
    active: false,
    title: 'v1.6.6',
    published: 'Apr-2021',
    major: false,
    is_new: false,
    items: [
      {
        title: `Nicht zugeteilte (Offene) aber benachrichtigte und bestätigte Anmeldungen lassen sich jetzt wieder öffnen.`,
        cat: 'Abgeschlossen',
        action: 'abgeschlossene-anmeldungen',
        fix: false
      },
      {
        title: `Die farbliche Kennzeichnung der Anmeldungen ist nun analog zur Abgeschlossenen- und Zuteilungsansicht.`,
        cat: 'Benachrichtigung',
        action: 'eltern-benachrichtigungen',
        fix: false
      },
      {
        title: `Offene Anmeldungen lassen sich nun nicht mehr bestätigen oder ablehnen`,
        cat: 'Bestätigung',
        action: 'bestaetigen',
        fix: false
      },
      {
        title: `Der CSV Export wurde um einen Export für winKITA erweitert`,
        cat: 'Export',
        action: 'zugeteilte-bedarfsanmeldungen-export',
        fix: false
      },
      {
        title: `Die Tooltips für Geschwisterkind und alternatives Aufnahmedatum sind nun präziser.`,
        cat: 'Zuteilung',
        action: 'kita-bedarfsanmeldungen',
        fix: false
      },
      {
        title: `Eine Filterung nach dem Kriterium 'Sie sind mit der Zuteilung dran' ist jetzt möglich.`,
        cat: 'Zuteilung',
        action: 'kita-bedarfsanmeldungen',
        fix: false
      },
    ]
  },
  {
    active: false,
    title: 'v1.6.5',
    published: 'Feb-2021',
    major: false,
    items: [
      {
        title: `Es lassen sich nun 25 Anmeldungen anzeigen.`,
        cat: 'Allgemein',
        action: null,
        fix: false
      },
      {
        title: `Der XML Export berücksichtigt nun das Alternative Aufnahmedatum über dem normalen Aufnahmedatum.`,
        cat: 'Export',
        action: 'zugeteilte-bedarfsanmeldungen-export',
        fix: false
      },
      {
        title: `Es ist nun möglich nach der Einrichtungsart einer Anmeldung zu filtern.`,
        cat: 'Zuteilung',
        action: 'kita-bedarfsanmeldungen',
        fix: false
      },
      {
        title: `Ein Fehler wurde behoben, der die die Kapazität nach Einrichtungsart betraf.`,
        cat: 'Zuteilung',
        action: 'kita-bedarfsanmeldungen',
        fix: true
      }
    ]
  },
  {
    active: false,
    title: 'v1.6.4',
    published: 'Feb-2021',
    major: false,
    items: [
      {
        title: `Es ist nun möglich eine interne Priorität für eine Anmeldung zu vergeben. Diese Priorität spiegelt die Dringlichkeit für die 
        Bearbeitung der Anmeldung wider.`,
        cat: 'Zuteilung',
        action: 'kita-bedarfsanmeldungen',
        fix: false
      },
      {
        title: `Die normale Kapazität wurde um die Kapazität nach Einrichtungsart erweitert.`,
        cat: 'Zuteilung',
        action: 'kita-bedarfsanmeldungen',
        fix: false
      }

    ]
  },
  {
    active: false,
    title: 'v1.6.3',
    published: 'Nov-2020',
    major: false,
    items: [
      {
        title: `
          Es ist nun möglich abgeschlossene Anmeldungen zu archivieren.
        `,
        cat: 'Abgeschlossen',
        action: 'abgeschlossene-anmeldungen',
        fix: false
      },
      {
        title: `
          Ein Fehler wurde behoben, der es nicht erlaubte zurückgestellte abgeschlossene Anmeldungen wiedereinzusetzen.
        `,
        cat: 'Abgeschlossen',
        action: 'abgeschlossene-anmeldungen',
        fix: true
      },
      {
        title: `Die Buttons zur Änderung und Speicherung befinden sich nun ebenfalls am rechten oberen Rand.`,
        cat: 'Adminbereich',
        action: null,
        fix: false
      },
      {
        title: `Die Rechtekonfiguration wurde erweitert.`,
        cat: 'Allgemein',
        action: null,
        fix: false
      },
      {
        title: `Änderungen an der Anzeige für unterjährige Anmeldungen.`,
        cat: 'Allgemein',
        action: null,
        fix: false
      },
      {
        title: 'Die Angabe des minimalen und maximalen Alters in den Einrichtungsarten erfolgt jetzt nach der Notation: "Jahre,Monate"',
        cat: 'Einrichtungsart',
        action: 'einrichtungsarten'
      }
    ]
  },
  {
    active: false,
    title: 'v1.6.2',
    published: 'Nov-2020',
    major: false,
    items: [
      {
        title: `Abgeschlossene Anmeldungen werden nun nicht mehr in der Zuteilungsansicht angezeigt,
        sondern in einer eigenen Ansicht. Als abgeschlossene Anmeldungen zählen alle Anmeldungen, die
        im Benachrichtigungsvorgang bestätigt oder abgelehnt worden sind. Alle anderen Anmeldungen
        tauchen weiterhin in der Zuteilungsansicht auf.
        `,
        cat: 'Abgeschlossen',
        action: 'abgeschlossene-anmeldungen',
        fix: false
      },
      {
        title: `Analog zum BSP wurde das Feld Konfession entfernt.`,
        cat: 'Allgemein',
        action: null,
        fix: false
      }
    ]
  },
  {
    active: false,
    title: 'v1.6.1',
    published: 'Sep-2020',
    major: false,
    items: [
      {
        title: `Die Tabelle wurde um die Spalte 'Alternatives Aufnahmedatum' erweitert.`,
        cat: 'Benachrichtigung',
        action: 'eltern-benachrichtigungen',
        fix: false
      },
      {
        title: `Es ist nun möglich eine Anmeldung abzulehnen.`,
        cat: 'Bestätigung',
        action: 'bestaetigen',
        fix: false
      },
      {
        title: `Die Anzahl der Bilder wurde auf zwei beschränkt.`,
        cat: 'Einrichtungen-Bilder',
        action: 'einrichtungen-bilder',
        fix: false
      },
      {
        title: `Der CSV Export wurde um die folgenden Felder erweitert: Alternatives Aufnahmedatum`,
        cat: 'Export',
        action: 'zugeteilte-bedarfsanmeldungen-export',
        fix: false
      },
      {
        title: `Die Charts können nun als Bilder herunterladen werden.`,
        cat: 'Statistik',
        action: 'anmeldungen/statistik',
        fix: false
      }
    ]
  },
  {
    active: false,
    title: 'v1.6.0',
    published: 'Aug-2020',
    major: false,
    items: [
      {
        title: `Die digitale Rückmeldung per EMail für die Vertreter steht nun zur Verfügung.`,
        cat: 'Allgemein',
        action: null,
        fix: false
      }
    ]
  },
  {
    active: false,
    title: 'v1.5.3',
    published: 'Aug-2020',
    major: false,
    items: [
      {
        title: `Der CSV Export wurde um die folgenden Felder erweitert: Kita_ID, Betreuungs_ID und Kind_ID`,
        cat: 'Export',
        action: 'zugeteilte-bedarfsanmeldungen-export',
        fix: false
      },
      {
        title: `Ein Session Timer wurde hinzugefügt. Dementsprechend findet nach zwei Stunden
        ein automatischer Logout statt.`,
        cat: 'Allgemein',
        action: null,
        fix: false
      },
    ]
  },
  {
    active: false,
    title: 'v1.5.2',
    published: 'Juli-2020',
    major: false,
    items: [
      {
        title: `Die Uhrzeiten werden nun für UTC+1 bzw. UTC+2 angezeigt.`,
        cat: 'Change Log',
        action: 'change-log',
        fix: false
      },
      {
        title: `Die Filter bleiben nun bestehen bei einem Wechsel der Änderungsart.`,
        cat: 'Change Log',
        action: 'change-log',
        fix: false
      },
      {
        title: `Es wurde ein Fehler behoben der dazu führte dass die Statistik nicht
        angezeigt wurde.`,
        cat: 'Statistik',
        action: 'anmeldungen/statistik',
        fix: true
      },
    ]
  },
  {
    active: false,
    title: 'v1.5.1',
    published: 'Juli-2020',
    major: false,
    items: [
      {
        title: `Es wurden verschiedene kleine Änderungen vorgenommen.`,
        cat: 'Allgemein',
        action: null,
        fix: false
      },
      {
        title: `Die FAQs in der Kurzbeschreibung können nun als PDF herunterladen werden.`,
        cat: 'Allgemein',
        action: null,
        fix: false
      },
      {
        title: `Die Suche wurde überarbeitet und ist nun änhlich zu der Suche in der Zuteilungsansicht.`,
        cat: 'Benachrichtigung',
        action: 'eltern-benachrichtigungen',
        fix: false
      },
      {
        title: `Es wurde ein Erklärungsdialog hinzugefügt, um bestimmte Mechanismen der Benachrichtigungen zu erklären.`,
        cat: 'Benachrichtigung',
        action: 'eltern-benachrichtigungen',
        fix: false
      },
      {
        title: `Die Suche wurde überarbeitet und ist nun änhlich zu der Suche in der Zuteilungsansicht.`,
        cat: 'Bestätigung',
        action: 'bestaetigen',
        fix: false
      },
      {
        title: `Die Saisonparameter wurden um das Feld 'Frist in Tage zur KITA-Platz-Bestätigung' erweitert.`,
        cat: 'Saisonparameter',
        action: 'saisonparameter',
        fix: false
      },
      {
        title: `Der Informationsdialog zu Einrichtungen wurde überarbeitet.`,
        cat: 'Zuteilung',
        action: 'kita-bedarfsanmeldungen',
        fix: false
      }
    ]
  },
  {
    active: false,
    title: 'v1.5.0',
    published: 'Mai-2020',
    major: false,
    items: [
      {
        title: `Der XML Export wurde um das Feld 'Einrichtungsnummer' erweitert. `,
        cat: 'Export',
        action: 'zugeteilte-bedarfsanmeldungen-export',
        fix: false
      },
      {
        title: `Es wurde der Change Log hinzugefügt. Mit diesem können Nutzer der Rolle
        Admin oder Kommune nachvollziehen, wann und von wem Änderungen durchgeführt wurden.  `,
        cat: 'Change Log',
        action: 'change-log',
        fix: false
      },
      {
        title: `Es wurde ein Erklärungsdialog hinzugefügt, um bestimmte Mechanismen der Zuteilung zu erklären.`,
        cat: 'Zuteilung',
        action: 'kita-bedarfsanmeldungen',
        fix: false
      }
    ]
  },
  {
    active: false,
    title: 'v1.4.2',
    published: 'Mai-2020',
    major: false,
    items: [
      {
        title: `Die Bilder der Einrichtungen werden nun vollständig angezeigt. `,
        cat: 'Einrichtungen-Bilder',
        action: 'einrichtungen-bilder',
        fix: true
      },
      {
        title: `Die Merkmale und Filtereinstellungen wurden um das Merkmal ad erweitert. Für Anmeldungen, die ein
        alternatives Aufnahmedatum gesetzt haben. `,
        cat: 'Zuteilung und Archiv',
        action: 'kita-bedarfsanmeldungen',
        fix: false
      },
    ]
  },
  {
    active: false,
    title: 'v1.4.1',
    published: 'Apr-2020',
    major: false,
    items: [
      {
        title: `Die Statistiken wurden aus dem Übersicht-Reiter der Zuteilungsansicht entfernt
        und in eine eigene Ansicht ausgelagert. `,
        cat: 'Statistik',
        action: 'anmeldungen/statistik',
        fix: false
      },
      {
        title: `Die Statistiken wurden um mehrere Diagramme erweitert. `,
        cat: 'Statistik',
        action: 'anmeldungen/statistik',
        fix: false
      }
    ]
  },
  {
    active: false,
    title: 'v1.4.0',
    published: 'Mar-2020',
    major: true,
    items: [
      {
        title: `Die Oberfläche der Anwendung wurde überarbeitet und ist nun leichter und
        übersichtlicher zu bedienen.`,
        cat: 'Allgemein',
        action: null,
        fix: false
      },
      {
        title: `Der Bedarfsanmeldungs - Dialog wurde grundlegend überarbeitet.`,
        cat: 'Allgemein',
        action: null,
        fix: false
      },
      {
        title: `Es ist nun möglich nach Zuteilungsstatus zu filtern.`,
        cat: 'Benachrichtigung',
        action: 'eltern-benachrichtigungen',
        fix: false
      },
      {
        title: `Die Tabelle wurde um das Feld Aufnahmedatum erweitert.`,
        cat: 'Benachrichtigung',
        action: 'eltern-benachrichtigungen',
        fix: false
      },
      {
        title: `Die Reihen der Tabelle werden nun farblich hinterlegt, ähnlich den Zuteilungen. `,
        cat: 'Benachrichtigung',
        action: 'eltern-benachrichtigungen',
        fix: false
      },
      {
        title: `Der XML Export wurde um die folgenden Felder erweitert: Staatsangehörigkeit (Vertreter),
        Herkunftsland (Vertreter), Arbeitgeber (Vertreter), Anmerkungen (Kind), Schule (Kind)`,
        cat: 'Export',
        action: 'zugeteilte-bedarfsanmeldungen-export',
        fix: false
      },
      {
        title: `Der Filtereinstellungen bleiben nun im Dialog erhalten und lassen
        sich somit ändern. Der Filter muss vorher nicht zurückgesetzt werden.`,
        cat: 'Zuteilung und Archiv',
        action: 'kita-bedarfsanmeldungen',
        fix: false
      },
      {
        title: `Eine Filterung nach Alter wurde hinzugefügt. Es ist möglich
        nach einer Altersspanne zu filtern.`,
        cat: 'Zuteilung und Archiv',
        action: 'kita-bedarfsanmeldungen',
        fix: false
      },
      {
        title: `Es werden nun Namensvorschläge gemacht bei der Suche nach einer Anmeldung.`,
        cat: 'Zuteilung und Archiv',
        action: 'kita-bedarfsanmeldungen',
        fix: false
      },
      {
        title: `Es ist nun möglich mehrere Namen bei der Suche nach einer Anmeldung auszuwählen.`,
        cat: 'Zuteilung und Archiv',
        action: 'kita-bedarfsanmeldungen',
        fix: false
      },
      {
        title: `Es ist nun möglich die Monate bei der Filterung nach Alter anzugeben.`,
        cat: 'Zuteilung und Archiv',
        action: 'kita-bedarfsanmeldungen',
        fix: false
      },
      {
        title: `Es ist nun möglich sich eine Anmeldung anzeigen zu lassen über die Suchfunktion.`,
        cat: 'Zuteilung und Archiv',
        action: 'kita-bedarfsanmeldungen',
        fix: false
      },
    ]
  },
  {
    active: false,
    title: 'v1.2.3',
    published: 'Feb-2020',
    major: false,
    items: [
      {
        title: `Anmeldungen, die von allein Einrichtungen abgelehnt
        wurden, werden nun gelb gekennzeichnet. Ebenfalls ist es nun
        möglich nach diesen Anmeldungen zu filtern.`,
        cat: 'Zuteilung',
        action: 'kita-bedarfsanmeldungen',
        fix: false
      }
    ]
  },
  {
    active: false,
    title: 'v1.2.2',
    published: 'Feb-2020',
    major: false,
    items: [
      {
        title: `Sollten Nutzer die Zuteilungsseite mit bestehenden nicht
        gespeicherten Änderungen verlassen wollen, werden diese nun gefragt,
        ob Sie die Seite wirklich verlassen möchten.`,
        cat: 'Zuteilung',
        action: 'kita-bedarfsanmeldungen',
        fix: false
      },
      {
        title: `Der XML Export wurde um das Feld Anmeldedatum erweitert.`,
        cat: 'Export',
        action: 'zugeteilte-bedarfsanmeldungen-export',
        fix: false
      },
      {
        title: `Das Wiedereinsetzen einzelner Anmeldungen ist nun für die Rolle
        Kommune möglich.`,
        cat: 'Archiv',
        action: 'archiv',
        fix: false
      },
    ]
  },
  {
    active: false,
    title: 'v1.2.0',
    published: 'Jan-2020',
    major: false,
    items: [
      {
        title: `Für Nutzer der Rolle Kommune ist es nun möglich einzelne Anmeldungen
        zu archivieren. Diese werden dann aus dem Zuteilungsprozess entfernt und
        in ein spezielles Archiv verschoben.`,
        cat: 'Archiv',
        action: 'archiv',
        fix: false
      },
      {
        title: `Für Nutzer der Rolle Kommune ist es nun möglich massenhaft Anmeldungen
        zu archivieren. Diese werden dann aus dem Zuteilungsprozess entfernt und
        in ein spezielles Archiv verschoben.`,
        cat: 'Archiv',
        action: 'archiv',
        fix: false
      },
      {
        title: `Für Nutzer der Rolle Kommune ist es nun möglich das Archiv einzusehen.`,
        cat: 'Archiv',
        action: 'archiv',
        fix: false
      }
    ]
  },
  {
    active: false,
    title: 'v1.1.1',
    published: 'Jan-2020',
    major: false,
    items: [
      {
        title: `Ein Fehler bei der Filterauswahl der Priorisierungen wurde behoben.`,
        cat: 'Zuteilung',
        action: 'kita-bedarfsanmeldungen',
        fix: true
      },
      {
        title: `Das Festlegen eines alternativen Aufnahmedatums und das Verfassen einer
        Bemerkung zu diesem ist nun möglich.`,
        cat: 'Benachrichtigung',
        action: 'eltern-benachrichtigungen',
        fix: false
      },
      {
        title: `Geänderte Anmeldungen werden nun gekennzeichnet.`,
        cat: 'Benachrichtigung',
        action: 'eltern-benachrichtigungen',
        fix: false
      }
    ]
  },
  {
    active: false,
    title: 'v1.1.0',
    published: 'Dez-2019',
    major: false,
    items: [
      {
        title: `Ein Fehler bei der Anzeige der Träger wurde behoben.`,
        cat: 'Adminbereich',
        action: null,
        fix: true
      }
    ]
  },
  {
    active: false,
    title: 'v1.0.0',
    published: 'Aug-2019',
    major: true,
    items: [
      {
        title: `Die Anwendung unterstützt nun Rechte und Rollen verschiedener Benutzer und
        bietet somit für jede Rolle bestimmte Funktionalitäten an. `,
        cat: 'Allgemein',
        action: null,
        fix: false
      },
      {
        title: `Neuer Vergabeprozess: Als Einrichtung (Rolle) müssen Sie nun warten,
        bis die Einrichtungen mit höherer Priorosierung als Sie gehandelt haben.`,
        cat: 'Zuteilung',
        action: 'kita-bedarfsanmeldungen',
        fix: false
      },
      {
        title: `Neuer Vergabeprozess: Als Einrichtung (Rolle) haben Sie nun zwei
        Möglichkeiten (wenn sie am Zug sind). Sie können die
        Anmeldung 'ANNEHMEN' oder 'ABLEHNEN'. Eine Annahme führt dazu, dass die Anmeldung
        an Sie zugeteilt wird, Einrichtungen mit Priorosierung nach Ihnen haben nun keine Möglichkeit
        mehr das Kind anzunehmen. Eine Ablehnung führt dazu, dass Einrichtung mit Priorosierung nach Ihnen die
        Möglichkeit bekommen das Kind anzunehmen. `,
        cat: 'Zuteilung',
        action: 'kita-bedarfsanmeldungen',
        fix: false
      },
      {
        title: `Neuer Vergabeprozess: Als Träger (Rolle) müssen Sie nicht warten bis die
        Einrichtungen den Priorosierungen folgend 'ANNEHMEN' oder 'ABLEHNEN'. Sie können frei zuteilen,
        solange Sie der Träger der Einrichtung sind.`,
        cat: 'Zuteilung',
        action: 'kita-bedarfsanmeldungen',
        fix: false
      },
      {
        title: `Neuer Vergabeprozess: Als Kommune (Rolle) müssen Sie nicht warten bis die
        Einrichtungen den Priorisierungen folgend 'ANNEHMEN' oder 'ABLEHNEN'. Sie können frei zuteilen. `,
        cat: 'Zuteilung',
        action: 'kita-bedarfsanmeldungen',
        fix: false
      },
      {
        title: `Neuer Vergabeprozess: Als Kommune oder Träger (Rollen) können Sie die bereits erfolgte Zuteilung
        einer Anmeldung zu einer Einrichtung ändern.`,
        cat: 'Zuteilung',
        action: 'kita-bedarfsanmeldungen',
        fix: false
      },
      {
        title: `Neuer Vergabeprozess: Als Einrichtung (Rolle) können Sie eine Annahme einer Anmeldung zu jedem
        Zeitpunkt rückgänig machen und doch noch 'ABLEHNEN'. Dann bekommen Einrichtungen nach Ihnen die Möglichkeit das Kind anzunehmen. `,
        cat: 'Zuteilung',
        action: 'kita-bedarfsanmeldungen',
        fix: false
      },
      {
        title: `Neuer Vergabeprozess: Als Einrichtung (Rolle) können Sie die Ablehnung einer Anmeldung rückgänig
        machen und doch noch 'ANNEHMEN' solange keine Einrichtung angenommen hat. `,
        cat: 'Zuteilung',
        action: 'kita-bedarfsanmeldungen',
        fix: false
      },
      {
        title: `Neuer Vergabeprozess: Als Kommune (Rolle) haben Sie die Möglichkeit eine Anmeldung zurückzustellen. `,
        cat: 'Zuteilung',
        action: 'kita-bedarfsanmeldungen',
        fix: false
      },
      {
        title: `Neuer Vergabeprozess: Als Kommune oder Träger (Rollen) haben Sie die Möglichkeit eine Anmeldung
        an eine nicht priorisierte Einrichtung zuzuteilen. `,
        cat: 'Zuteilung',
        action: 'kita-bedarfsanmeldungen',
        fix: false
      }
    ]
  },
  {
    active: false,
    title: 'v0.0.18',
    published: 'Aug-2019',
    major: false,
    items: [
      {
        title: `Die Auswahlmenüs im Adminbereich unterstützten jetzt neben einer simpler Auswahl
        auch eine Suche nach dem Namen der Einrichtung, Trägers, etc. .`,
        cat: 'Adminbereich',
        action: null,
        fix: false
      },
      {
        title: `Es wurden verschieden Dialoge zur Erklärung einiger Funktionen hinzugefügt.`,
        cat: 'Allgemein',
        action: null,
        fix: false
      },
      {
        title: `Ungespeicherte Zurückstellungen oder manuelle Benachrichtigung lassen sich
        jetzt lokal rückgänig machen. `,
        cat: 'Benachrichtigung',
        action: 'eltern-benachrichtigungen',
        fix: false
      }
    ]
  },
  {
    active: false,
    title: 'v0.0.17',
    published: 'Aug-2019',
    major: false,
    items: [
      {
        title: 'Es wurde eine Übersicht über die Änderungen hinzugefügt. ',
        cat: 'Änderungsübersicht',
        action: 'aenderungs-uebersicht'
      },
      {
        title: `Bilder für Einrichtungen werden jetzt separat der Einrichtung verwaltet.`,
        cat: 'Einrichtungen-Bilder',
        action: 'einrichtungen-bilder'
      }
    ]
  },
  {
    active: false,
    title: 'v0.0.16',
    published: 'Jul-2019',
    major: false,
    items: [
      {
        title: `Die Anzeige der Übersicht wurde erweiter und überarbeitet.`,
        cat: 'Zuteilung',
        action: 'kita-bedarfsanmeldungen'
      },
    ]
  },
  {
    active: false,
    title: 'v0.0.15',
    published: 'Jun-2019',
    major: false,
    items: [
      {
        title: 'Es können nun mehrere Bilder für eine Einrichtung hochgeladen werden.',
        cat: 'Einrichtungen-Bilder',
        action: 'einrichtungen-bilder'
      },
      {
        title: 'Einrichtungsart enthält nun auch das minimal und maximale Alter des Kindes',
        cat: 'Einrichtungsart',
        action: 'einrichtungsarten'
      },
      {
        title: `Es ist nun möglich einen Stichtag für die Benachrichtigungen anzugeben,
        bis zu dem die Eltern handeln sollten.`,
        cat: 'Benachrichtigung',
        action: 'eltern-benachrichtigungen'
      },
      {
        title: `Der PDF Export nach einem gestarteten Benachrichtigungsvorgang ist nun optional abwählbar.`,
        cat: 'Benachrichtigung',
        action: 'eltern-benachrichtigungen'
      },
      {
        title: `Anmeldungen sind nun auch nach ihrem Status zu filtern.`,
        cat: 'Zuteilung',
        action: 'kita-bedarfsanmeldungen'
      },
    ]
  },
  {
    active: false,
    title: 'v0.0.14',
    published: 'Mai-2019',
    major: false,
    items: [
      {
        title: 'Neue Felder für den XML Export der Anmeldungen',
        cat: 'Export',
        action: 'zugeteilte-bedarfsanmeldungen-export'
      }
    ]
  },
  {
    active: false,
    title: 'v0.0.13',
    published: 'Apr-2019',
    major: false,
    items: [
      {
        title: 'CSV Export. Die Anmeldungen können nun als CSV Datei mit frei selektierbaren Spalten exportiert werden.',
        cat: 'Export',
        action: 'zugeteilte-bedarfsanmeldungen-export'
      }
    ]
  },
  {
    active: false,
    title: 'v0.0.12',
    published: 'Apr-2019',
    major: false,
    items: [
      {
        title: 'Eine Anmeldung lässt sich nun manuell auf den Status benachrichtigt setzen.',
        cat: 'Benachrichtigung',
        action: 'eltern-benachrichtigungen'
      },
      {
        title: `Es ist nun möglich einen Grund für eine Anmeldung zu verfassen.
        Der Grund rechtfertigt den Status zurückgestellt oder offen
        und wird den Eltern in der Benachrichtigung mitgeteilt.`,
        cat: 'Zuteilung',
        action: 'kita-bedarfsanmeldungen'
      }
    ]
  },
  {
    active: false,
    title: 'v0.0.11',
    published: 'Apr-2019',
    major: false,
    items: [
      {
        title: 'Benachrichtigungen lassen sich nun als Sicherheit als PDF herunterladen. ',
        cat: 'Benachrichtigung',
        action: 'eltern-benachrichtigungen'
      },
      {
        title: `Es ist nun möglich die Eltern über den Status der Anmeldung automatisch benachrichtigen zu lassen.`,
        cat: 'Benachrichtigung',
        action: 'eltern-benachrichtigungen'
      }
    ]
  },
  {
    active: false,
    title: 'v0.0.10',
    published: 'März-2019',
    major: false,
    items: [
      {
        title: `Es ist nun möglich für eine Anmeldung eine Bemerkung zu verfassen.
         Diese Bemerkung sollte während des Vergabeprozesses beachtet werden und
         wird nicht nach außen kommuniziert.`,
         cat: 'Zuteilung',
         action: 'kita-bedarfsanmeldungen'
      },
      {
        title: `Bei einer Sortierung nach Namen werden die Anmeldungen nicht
        mehr nach Vornamen des Kindes sortiert, sondern nach Nachname des Kindes.`,
        cat: 'Zuteilung',
        action: 'kita-bedarfsanmeldungen'
      },
      {
        title: `Unterjährige Anmeldungen werden nun besonders gekennzeichnet.`,
        cat: 'Zuteilung',
        action: 'kita-bedarfsanmeldungen'
      }
    ]
  },
  {
    active: false,
    title: 'v0.0.09',
    published: 'März-2019',
    major: false,
    items: [
      {
        title: `Eine Zuteilung zu einer Einrichtung, die nicht von den Eltern
        priorisiert wurde, ist nun möglich. `,
        cat: 'Zuteilung',
        action: 'kita-bedarfsanmeldungen'
      },
      {
        title: `Es ist nun möglich die Anmeldungen nach unterschiedlichen Kriterien zu filtern`,
        cat: 'Zuteilung',
        action: 'kita-bedarfsanmeldungen'
      }
    ]
  },
  {
    active: false,
    title: 'v0.0.8',
    published: 'Jan-2019',
    major: false,
    items: [
      {
        title: `Anmeldungen können jetzt als .xml Datei exportiert werden.`,
        cat: 'Export',
        action: 'zugeteilte-bedarfsanmeldungen-export'
      }

    ]
  },
  {
    active: false,
    title: 'v0.0.7',
    published: 'Jan-2019',
    major: false,
    items: [
      {
        title: `Es werden nun zusätzliche Informationen eingeblendet wenn
        mit dem Mauszeiger über die Spalte einer Anmeldung gefahren wird. `,
        cat: 'Zuteilung',
        action: 'kita-bedarfsanmeldungen'
      }

    ]
  },
  {
    active: false,
    title: 'v0.0.6',
    published: 'Dez-2018',
    major: false,
    items: [
      {
        title: `Es werden nun Anmeldungen mit mehreren Kindern unterstützt. `,
        cat: 'Zuteilung',
        action: 'kita-bedarfsanmeldungen'
      }
    ]
  },
  {
    active: false,
    title: 'v0.0.5',
    published: 'Dez-2018',
    major: false,
    items: [
      {
        title: `Saisonparameter werden nun nach chronologischen Aspelten validiert.`,
        cat: 'Saisonparameter',
        action: 'saisonparameter'
      }
    ]
  },
  {
    active: false,
    title: 'v0.0.4',
    published: 'Nov-2018',
    major: false,
    items: [
      {
        title: `Es ist nun möglich nach einer Anmeldung mit dem Namen des Kindes
        oder den Namen der Eltern zu suchen. `,
        cat: 'Zuteilung',
        action: 'kita-bedarfsanmeldungen'
      }
    ]
  }
  ]

}
