<template>
  <div>
    <v-list-item
      @click="assignRegistration"
      :disabled="tileProps.disabled"
      :style="tileProps.backgroundColor"
      >
      <template v-slot:prepend>
      <v-list-item-action class="pr-6">
        <Tooltip top>
            <v-icon :color="tileProps.iconColor" size="large">
                {{ tileProps.iconName }}
            </v-icon>
          <template #content>
            <span>
                {{ tileProps.tooltipText}}
            </span>
          </template>
        </Tooltip>
      </v-list-item-action>
      </template>
      <div>
        <v-list-item-title>
          {{ tileProps.headerText }}
        </v-list-item-title>

        <v-list-item-subtitle v-if="tileProps.prio">
            mit Priorität {{ tileProps.prio }}<span v-if="tileProps.prio_status === null"> - Noch nicht gehandelt</span><span v-else>- {{tileProps.prio_status}}</span>
        </v-list-item-subtitle>

        <v-list-item-subtitle>
          {{ tileProps.subheaderText }}
        </v-list-item-subtitle>
      </div>
    </v-list-item>
    <v-divider light></v-divider>
  </div>
</template>
<script>
import Tooltip from '@s/views/general_components/components/Tooltip'

/**
 * The list component is a child component of the menu component and displays
 * the facilities to which a registration can be assigned to. Giving visual
 * feeback on the status of the free seats a facility has.
 */
export default {
    name: "list-component",
    components: {
      Tooltip
    },
    props: {
        /**
         * The properties of a list tile. Every facility gets rendered on one
         * tile. Depening on the status of the facility with various visual
         * characteristics. For example: No free seats --> red background
         */
        tileProps: {
          type: Object,
          default: () => {
            return {
              iconName: '',
              iconColor: '',
              backgroundColor: '',
              headerText: '',
              subheaderText: '',
              disabled: false,
              prio: {
                type: Number,
                required: false
              },
              status: null,
              prio_status: {
                type: String,
                default: ''
              }
            }
          }
        },
        einrichtung: {
          type: Object
        },
    },
    methods: {
        assignRegistration() {

        /**
          * Triggers when the assign registration was clicked.
          *
          * @property {object} item Facility that was clicked.
          */
          this.$emit('assignRegistration', {
              ...this.einrichtung
          })
        }
    }
}
</script>