<template>
<v-main>
    <v-container fluid class="fill-height">
        <v-row align-center justify-center style="justify-content: center; align-items: center;">
            <v-col v-if="trustlevel !== 0 && fetched" cols="12" md="4" sm="8">
                <welcome />
            </v-col>
            <v-col v-else cols="12" md="4" sm="8">
                <login />
            </v-col>
        </v-row>
    </v-container>
</v-main>
</template>
<script>
import Login from './Login.vue'
import Welcome from './Welcome.vue'
export default {
    name: 'index-page',
    data() {
        return {
            login: {
                user: '',
                pw: '',
            },
            trustlevel: this.$select('auth.trustlevel as trustlevel'),
            fetched: this.$select('user.fetched as fetched')
        }
    },
    components: {
        Login,
        Welcome,
    }
}
</script>
