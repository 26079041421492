import Dashboard from './Dashboard.vue';
import Beschreibung from './general/Beschreibung.vue'
import KitaBedarfsanmeldungen from './kita-bedarfsanmeldungen/Bedarfsanmeldungen.vue'
import PatchNotes from './general/PatchNotes.vue'
import UserAccount from './general/UserAccount.vue'


const routes = [{
    path: '',
    component: Beschreibung
}, {
    path: 'kita-bedarfsanmeldungen',
    component: KitaBedarfsanmeldungen
}, {
    path: 'be-detailpflege',
    component: () => import(`./admin/einrichtungen/Admin.vue`)
}, {
    path: 'einrichtungsarten',
    component: () => import(`./admin/einrichtungsarten/Einrichtungsarten.vue`)
}, {
    path: 'traeger',
    component: () => import(`./admin/traeger/Traeger.vue`)
}, {
    path: 'saisonparameter',
    component: () => import(`./admin/saisonparameter/Saisonparameter.vue`)
}, {
    path: 'bedarfsanmeldung-erstellung',
    component: () => import(`./kita-bedarfsanmeldungen/erstellung/BAErstellung.vue`)
}, {
    path: 'Mandantparameter',
    component: () => import(`./admin/mandantparameter/Mandantparameter.vue`)
}, {
    path: 'zugeteilte-bedarfsanmeldungen-export',
    component: () => import(`./kita-bedarfsanmeldungen/registration-export/Export.vue`)
}, {
    path: 'eltern-benachrichtigungen',
    component: () => import(`./kita-bedarfsanmeldungen/notifications/Notifications.vue`)
}, {
    path: 'bestaetigen',
    component: () => import(`./kita-bedarfsanmeldungen/confirmation/Confirmation.vue`)
}, {
    path: 'export',
    component: () => import(`./admin/export/Export.vue`)
}, {
    path: 'kennzahlen',
    component: () => import(`./admin/Kennzahlen.vue`)
}, {
    path: 'einrichtungen-bilder',
    component: () => import(`./admin/einrichtungen-bilder/Images.vue`)
}, {
    path: 'aenderungs-uebersicht',
    component: PatchNotes
}, {
    path: 'benutzerkonto',
    component: UserAccount
}, {
    path: 'archiv',
    component: () => import(`./kita-bedarfsanmeldungen/archive/Archive.vue`)
}, {
    path: 'archiv-einleiten',
    component: () => import(`./kita-bedarfsanmeldungen/archive/InitialiseArchive.vue`)
}, {
    path: 'archiv-wiedereinsetzen',
    component: () => import(`./kita-bedarfsanmeldungen/archive/RestoreArchive.vue`)
}, {
    path: 'change-log',
    component: () => import(`./general/changelog/ChangeLog.vue`)
}, {
    path: 'anmeldungen/statistik',
    component: () => import(`./kita-bedarfsanmeldungen/statistics/Statistics.vue`)
}, {
    path: 'abgeschlossene-anmeldungen',
    component: () => import(`./kita-bedarfsanmeldungen/final-states/Final.vue`)
},];

export { Dashboard };
export { routes };