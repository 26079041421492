<template>
  <div>
  <page-card>
      <template v-slot:actions>
          <v-select
            v-if="isDevelopmentMode"
            class="px-4 pt-4"
            v-model="roleSelected"
            :items="testRoles"
            label="Test Roles"
            density="comfortable"
            color="primary"
          ></v-select>
          <v-btn
            :loading="userIsFetching"
            :disabled="userIsFetching"
            @click="onSubmit"
            prepend-icon="mdi-login"
            class="bg-primary"
            color="white">
            Anmeldung KITA Platz
          </v-btn>
      </template>
  </page-card>
</div>

</template>
<script>
import PageCard from './PageCard.vue'
import Button from '@s/views/general_components/components/Button'
export default {
    name: 'login',
    computed: {
      isDevelopmentMode() {
        return !!module.hot || process.env.NODE_ENV === 'development'
      },
    },
    data() {
        return {
            authIsFetching: this.$select('auth.isFetching as authIsFetching'),
            userIsFetching: this.$select('user.isFetching as userIsFetching'),
            userLoggedIn: this.$select('user.value.loggedIn as userLoggedIn'),
            testRoles: ['testaccountER1', 'testaccountER2', 'testaccountER3',
            'testaccountER4', 'testaccountER5', 'testaccountER6', 'testaccountTV1', 'testaccountTV2',
            'testaccountKV', 'testaccountSA'],
            roleSelected: null
        }
    },
    watch: {
      userLoggedIn: function(newVal){
        if(newVal !== null){
          if(newVal == false){
          } else {
            this.$dispatch(this.$actions().auth.isTrusted()).then(() => {
              this.$nextTick(() => {
                    this.$router.replace('/dashboard/kita-bedarfsanmeldungen');
              })
            });
          }
        }
      }
    },
    methods: {
        onSubmit() {
          if(this.isDevelopmentMode) {
            window.location.href = `/user/authenticate/${this.roleSelected}`;
            return;
          }
          window.location.href = `/user/authenticate`;
        },
        navigateUserManagement(){
          window.location.href = `/user/usermanagement`
        }
    },
    components: {
      PageCard,
      Button
    }
}
</script>
