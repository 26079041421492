<template>
<div>
  <einrichtung-information-dialog ref="einrichtungInformationDialog">
  </einrichtung-information-dialog>
  <v-data-table
    v-model:expanded="expanded"
    :headers="headers"
    item-key="id"
    :items="einrichtungen"
    :loading="loading"
    class="elevation-1"
    :search="search"
    :items-per-page-options="rowsPerPageItems"
    :items-per-page-text="rowsPerPageText"
    >
      <template v-slot:top>
         <v-row row wrap>
          <v-col cols="6">
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Nach Einrichtung suchen"
            placeholder="Nach Einrichtung suchen"
            single-line
            clearable
            clear-icon="mdi-close-circle"
            prepend-icon="mdi-magnify"
          ></v-text-field>
          </v-col>
          <v-col cols="6">
          <v-switch
            v-model="showAuslastung"
            color="primary"
            label="Kapazität pro Einrichtungsart anzeigen"
          ></v-switch>
          </v-col>
      </v-row>
      </template>
      <template v-slot:item={item}>
        <div>
        </div>
        <tr :style="`background: linear-gradient(110deg, ${item.freiePlaetze < 0 ? 'rgb(142, 0, 0, 0.5)' : 'rgb(0, 107, 3, 0.5) '} ${((item.gesamtPlaetze - item.freiePlaetze) / item.gesamtPlaetze) * 100}%, rgb(0, 107, 3, 0.2) ${((item.gesamtPlaetze - item.freiePlaetze) / item.gesamtPlaetze) * 100}%)`">
          <td class="text-left"> {{  item.name }} </td>
          <td class="text-left"> {{ item.gesamtPlaetze }} </td>
          <td class="text-left"> {{ item.gesamtPlaetze - item.freiePlaetze }} </td>
          <td class="text-left">
            <span v-bind:class="( item.freiePlaetze < 0) ? 'font-weight-bold' : ''">
              {{ item.freiePlaetze }}
            </span>
          </td>
          <td class="text-right">
            <Tooltip top>
                <Button
                  class="v-btn--round theme--light v-size--default"
                  icon
                  @click="openEinrichtungInformationDialog(item)">
                  <v-icon color="primary">
                      mdi-information
                  </v-icon>
                </Button>
              <template #content>
                <span>Vollständige Information</span>
              </template>
            </Tooltip>
          </td> 
        </tr>
      </template>
      <template v-slot:expanded-row="{ columns, item }">
        <td :colspan="columns.length">
          <v-card class="pb-5">
            <v-card-title primary-title>
              <div style="width: 100%" align="center">
                  <v-divider></v-divider>
                  <span class="text-h5 text-primary-darken-2">Kapazität pro Einrichtungsart</span>
                  <v-divider class="pb-2"></v-divider>
              </div>
            </v-card-title>
            <div v-for="(it, index) in item.plaetzeProEinrichtungsart" class="mx-5" :key="index">
              <div v-if="it.einrichtungsart !== null">
                <h3 class="text-primary text-left">{{ einrichtungsarten_dic[it.einrichtungsart].name }}</h3>
                <div align="left">
                  <span class="text-grey text-caption ">Plätze frei: {{ it.plaetze.freiePlaetze }}</span>
                  <span class="text-grey text-caption pl-4">Plätze gesamt: {{ it.plaetze.gesamtPlaetze }}</span>
                </div>
                <v-progress-linear
                  class="mt-0"
                  :color="it.plaetze.freiePlaetze < 0 ? 'red' : 'green'"
                  :model-value="((it.plaetze.gesamtPlaetze - it.plaetze.freiePlaetze) / it.plaetze.gesamtPlaetze) * 100"
                />
              </div>
            </div>
          </v-card>
        </td>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import EinrichtungInformationDialog from './dialogs/EinrichtungInformationDialog.vue'
import Tooltip from '@s/views/general_components/components/Tooltip'
import Button from '@s/views/general_components/components/Button'


export default {
    name: "capacity-vue",
    data() {
        return {
          einrichtungsarten_dic: this.$select('general.einrichtungsarten_dic as einrichtungsarten_dic'),
          headers: [
            {
                title: 'Einrichtung',
                align: 'left',
                sortable: true,
                key: 'name'
            },
            {
                title: 'Plätze gesamt',
                align: 'left',
                sortable: true,
                key: 'gesamtPlaetze'
            },
            {
                title: 'Plätze belegt',
                align: 'left',
                sortable: false,
                key: ``
            },
            {
                title: 'Plätze frei',
                align: 'left',
                sortable: true,
                key: 'freiePlaetze'
            },
            {
                title: '',
                align: 'right',
                sortable: false,
                key: ''
            }
          ],
          einrichtungInformationDialog: null,
          rowsPerPageItems: [5, 10, 20, { "title": "Alle", "value": -1 }],
          rowsPerPageText: "Anzahl der Reihen pro Seite",
          expanded: [],
          showAuslastung: false,
          search: null
        }
    },
    props: {
      einrichtungen: {
        type: Array
      },
      loading: {
        type: Boolean
      }
    },
    mounted(){
      this.$dispatch(this.$actions().traeger.fetchTraeger(() => {

      },
      (error) => {
        if(error.status === 401){
          this.$dispatch(this.$actions().user.changeLogin())
        }
      }));
    },
    methods: {
      openEinrichtungInformationDialog(einrichtung){
        this.$nextTick(() => {
            this.$refs.einrichtungInformationDialog.open(einrichtung);
        });
      },
    },
    components: {
      EinrichtungInformationDialog,
      Tooltip,
      Button
    },
    watch: {
      showAuslastung(new_val){
        if(new_val){
          this.expanded = this.einrichtungen.filter(x => x.plaetzeProEinrichtungsart !== null && x.plaetzeProEinrichtungsart.length > 0).map(x => x.id);
        } else {
          this.expanded = [];
        }
      }
    }
}
</script>
