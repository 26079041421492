<template>
<div>
    <v-row>
        <v-col class="pt-4">
          <v-toolbar color="primary">
            <v-toolbar-title class="text-white d-flex justify-start">
            <v-app-bar-nav-icon>
                <v-icon color="white">mdi-account-circle</v-icon>
            </v-app-bar-nav-icon>Kontoinformation</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card class="mb-4 pb-4">
            <v-row class="mx-4 my-4">
              <v-col>
                <v-row>
                  <v-col>
                      <h4 class="text-primary text-left">Benutzername: {{ user.value.username }}</h4>
                  </v-col>
                  <v-col xs12 md6>
                      <h4 class="text-primary text-left">Rolle: {{ user.role }}</h4>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="mx-4 mb-4">
              <v-col class="px-4">
                  <h4 
                  class="text-primary text-left">AGS: <span v-for="(a, i) in user.value.ags" :key="i">{{ a }}<span v-if="i < user.value.ags.length - 1">, </span></span></h4>
              </v-col>
            </v-row>
            <v-col class="px-6">
              <h2 class="text-primary pb-2 text-left">Rechte - Informationen</h2>
              <v-expansion-panels>
                <v-expansion-panel expand v-for="(item, key, index) in permissionsSorted" :key="index">
                  <v-expansion-panel-title>
                    <span class="text-primary">{{ item }}</span>
                  </v-expansion-panel-title>
                  <v-expansion-panel-text>
                    <template v-slot:header>
                      <div>
                          <h4 class="text-primary">{{ permissionDict[item] }} </h4>
                      </div>
                    </template>
                    <v-row justify="start">
                    <div v-for="(s, index) in ['lesen / anzeigen','ändern','löschen','anlegen']" class="pl-5 py-2 flex" :key="index" style="display: inline">
                        <v-chip
                          class="ma-2"
                          color="success"
                          variant="outlined"
                        >
                          <v-icon start v-if="permissions[item].includes(s)">
                            mdi-check
                          </v-icon>
                          <v-icon v-else>
                            mdi-close
                          </v-icon>
                          {{ firstLetterUpperCase(s) }}
                        </v-chip>
                    </div>
                    </v-row>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-card>
        </v-col>
    </v-row>
</div>
</template>
<script>
import { firstLetterUpperCase } from '@s/util/helper'

export default {
    name: "user-account",
    data() {
        return {
          user: this.$select('user as user'),
          permissions: this.$select('user.permissions as permissions'),
          permissionDict: {
            'AGS': 'AGS - Werte',
            'Ansprechpartner': 'Ansprechpartner',
            'Bedarfsanmeldung': 'Bedarfsanmeldungen',
            'Bedarfsanmeldung.archiv': 'Archiv',
            'Einrichtung': 'Einrichtungen',
            'Einrichtung.einrichtungsnummer': 'Einrichtungsnummern',
            'Einrichtung.images': 'Einrichtungsbilder',
            'Einrichtung.oeffnungszeiten': 'Einrichtungsöffnungszeiten',
            'Einrichtungsart': 'Einrichtungsarten',
            'Mandantparameter': 'Mandantparameter',
            'Postkorbnachricht': 'Postkorbnachrichten',
            'Postkorbnachricht.only': 'Rechte für Postkorbnachricht aber nicht Zuteilung',
            'Traeger': 'Träger',
            'Saisonparameter': 'Saisonparameter',
          },
          permissionsSorted: []
        }
    },
    mounted(){
      const rightsNotToDisplay = ['Bestaetigung.save', 'Einrichtung.metadata',
      'Einrichtung.name', 'Mandantparameter.gebuehren-url', 'Mandantparameter.kibe-url',
      'Postkorbnachricht.send', 'Saisonparameter.einrichtung-amount', 'Traeger.Traegerart', 'Textbaustein'];

      Object.keys(this.permissions).forEach(key => {
        if(!rightsNotToDisplay.includes(key)){
          this.permissionsSorted.push(key);
        }
      })
      this.permissionsSorted.sort();
    },
    methods: {
      firstLetterUpperCase,
    }
}
</script>

<style lang="stylus" scoped>

</style>
