<template>
<div>

    <FeedbackDialog
      ref="feedbackDialog"
      :title="feedbackDialog.title"
      :isSending="isSending"
      :routingPoint="feedbackDialog.routingPoint"
      @success="sendCapacity" 
    />

    <ConfirmationDialog
      ref="confirmationDialog"
      :title="confirmationDialog.title"
      :text="confirmationDialog.text"
      :color="confirmationDialog.color"
      @confirm="confirmEventHandler" 
    />

    <NewFeedbackDialog
      ref="newFeedbackDialog"
      :title="newFeedbackDialog.title"
      :isSending="newFeedbackDialog.isSending" 
    />

    <HelperDialog ref="helperDialog" />

    <PageLeaveDialog
      ref="pageLeaveDialog"
      @leave="leave"
      @stay="stay"
      :nextFunction="next" />

    <v-snackbar
      v-model="snackbar.val"
      :color="snackbar.type"
    >
      {{ snackbar.text }}
      <Button
        color="white"
        @click="snackbar = false"
        icon
      >
      <v-icon>
          mdi-close
      </v-icon>
      </Button>
    </v-snackbar>

    <v-row row wrap>
       <v-col cols="12" md="12" lg="12" xl="10" offset-xl="1" class="mb-4 pt-4">
            <v-app-bar class="relative-toolbar" color="primary">
              <template v-slot:prepend>
                <v-app-bar-nav-icon> 
                  <v-menu>
                    <template v-slot:activator="{ props }">
                        <v-btn v-bind="props" icon>
                            <v-icon color="white">mdi-note-multiple-outline</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                        @click="openConfirmationDialog('Bedarfsanmeldungen speichern',
                        `Sie sind im Begriff die Einteilung, der von Ihnen bearbeiteten Bedarfsanmeldungen
                        permanent zu speichern. Die von Ihnen getätigte Zuteilung bleibt bestehen.
                        Bei Ablehnen einer Bedarfsanmeldung wird diese entsprechend für die nächste Priorität freigegeben.`,
                        'sendRegistrations')"
                        :disabled="changed.length === 0 || !permissions.Bedarfsanmeldung.includes('ändern')"
                        >
                          
                          <template v-slot:prepend>
                            <v-icon color="primary">mdi-content-save</v-icon>
                          </template>
                          <div>
                            <v-list-item-title>Änderungen speichern</v-list-item-title>
                            <v-list-item-subtitle>Speichert all Ihre ungespeicherten Änderungen</v-list-item-subtitle>
                          </div>
                        </v-list-item>
                        <v-divider class="my-2"></v-divider>
                        <v-list-item
                        @click="openHelperDialog"
                        >
                          <template v-slot:prepend>
                            <v-icon color="primary">mdi-help-circle-outline</v-icon>
                          </template>
                          <div>
                            <v-list-item-title>Erklärungsdialog</v-list-item-title>
                            <v-list-item-subtitle>Erklärungen und Hilfen anzeigen</v-list-item-subtitle>
                          </div>
                        </v-list-item>
                      </v-list>
                  </v-menu>
                </v-app-bar-nav-icon>
               </template>
              <v-app-bar-title style="flex: none">Bedarfsanmeldungen</v-app-bar-title>
              <v-spacer></v-spacer>
              <Tooltip top v-if="changed.length !== 0">
                  <v-icon color="white" class="pr-2 pointer">
                      mdi-wrench
                  </v-icon>
                <template #content>
                  <span>Ungespeicherte Änderungen liegen vor</span>
                </template>
              </Tooltip>
              <Tooltip top v-if="changed.length !== 0">
                  <Button
                    style="background-color: white !important"
                    class="mr-4 ml-4"
                    @click="openConfirmationDialog('Bedarfsanmeldungen speichern',
                    `Sie sind im Begriff die Einteilung, der von Ihnen bearbeiteten Bedarfsanmeldungen
                    permanent zu speichern. Die von Ihnen getätigte Zuteilung bleibt bestehen.
                    Bei Ablehnen einer Bedarfsanmeldung wird diese entsprechend für die nächste Priorität freigegeben.`,
                    'sendRegistrations')"
                    :disabled="!permissions.Bedarfsanmeldung.includes('ändern')">
                    <v-icon color="primary" class="pr-2">mdi-content-save</v-icon>
                    <span class="text-primary"><b>Speichern</b></span>
                  </Button>
                <template #content>
                  <span>Änderungen speichern</span>
                </template>
              </Tooltip>
            </v-app-bar>
            <div>
              <v-stepper show-actions non-linear>
              <template v-slot:default="{ prev, next }">
                <v-stepper-header>
                  <v-divider></v-divider>
                  <v-stepper-item
                    editable
                    value="1"
                  >
                    Bedarfsanmeldungen
                  </v-stepper-item>

                  <v-divider></v-divider>

                  <v-stepper-item
                    editable
                    value="2"
                  >
                    Auslastung
                  </v-stepper-item>
                  <v-divider></v-divider>
                </v-stepper-header>
                <v-stepper-window>
                  <v-stepper-window-item value="1">
                    <Table
                                  :registrations="registrations"
                                  :einrichtungen="einrichtungen"
                                  :loading="bedarfsAnmeldungIsFetching"
                                  :editable="true"
                                  :error="hasError"
                                  @reclineRegistration="reclineBedarfsanmeldung"
                                  @undoRecline="undoReclineBedarfsanmeldung"
                                  @assignRegistration="assignRegistration"
                                  @declineRegistration="declineRegistration"
                                  @addComment="addComment"
                                  @emitArchive="archiveRegistration"
                                  @emitInternalPriority="assignInternalPriority"
                                  >
                                  </Table>
                  </v-stepper-window-item>
                  <v-stepper-window-item value="2">
                    <Capacity :einrichtungen="einrichtungen" :loading="bedarfsAnmeldungIsFetching" />
                  </v-stepper-window-item>
                  <v-stepper-actions class="mt-4" color="primary" prev-text="Bedarfsanmeldungen" next-text="Auslastung" @click:next="next" @click:prev="prev" ></v-stepper-actions>
                </v-stepper-window>
              </template>
              </v-stepper>
            </div>
        </v-col>
    </v-row>
</div>
</template>
<script>
import Table from './assignment/Table.vue'
import Capacity from './assignment/Capacity.vue'
import FeedbackDialog from '../../general_components/dialogs/FeedbackDialog.vue'
import NewFeedbackDialog from '../../general_components/dialogs/NewFeedbackDialog.vue'
import ConfirmationDialog from '../../general_components/dialogs/ConfirmationDialog.vue'
import PageLeaveDialog from './assignment/dialogs/PageLeaveDialog.vue'
import HelperDialog from './assignment/dialogs/HelperDialog.vue'
import Tooltip from '@s/views/general_components/components/Tooltip'
import Button from '@s/views/general_components/components/Button'

/**
 *
 * @requires ./assignment/Table.vue
 * @requires ./assignment/table-components/MenuComponent.vue
 * @requires ./assignment/table-components/ListComponent.vue
 * @requires ./assignment/table-components/AdditionalKitaComponent.vue
 * @requires ./assignment/table-components/AdditionalActionComponent.vue
 * @requires ./assignment/table-components/EinrichtungActionComponent.vue
 * @requires ./assignment/table-components/TableCol.vue
 * 
 * @requires ./assignment/dialogs/NewAllInformationDialog.vue
 * @example ./Bedarfsanmeldungen.doc.md
 *
 */
export default {
    name: "kita-bedarfsanmeldungen",
    data() {
        return {
            stepperVal: 1,
            registrations: this.$select('bedarfsAnmeldung.tableProjection as registrations'),
            changed: this.$select('bedarfsAnmeldung.changed as changed'),
            registrationsChanged: this.$select('bedarfsAnmeldung.bedarfsanmeldungenChanged as registrationsChanged'),
            einrichtungen: this.$select('bedarfsAnmeldung.einrichtungen as einrichtungen'),
            user: this.$select('user.value as user'),
            role: this.$select('user.role as role'),
            bedarfsAnmeldungIsFetching: this.$select('bedarfsAnmeldung.isFetching as bedarfsAnmeldungIsFetching'),
            isSending: this.$select('bedarfsAnmeldung.isSending as isSending'),
            hasError: this.$select('bedarfsAnmeldung.hasError as hasError'),
            inc: this.$select('bedarfsAnmeldung.increaseId as inc'),
            dec: this.$select('bedarfsAnmeldung.decreaseId as dec'),
            size: this.$select('bedarfsAnmeldung.rowsPerPage as size'),
            permissions: this.$select('user.permissions as permissions'),
            maxFetchingSize: this.$select('general.maxFetchingSize as maxFetchingSize'),
            type_changed: this.$select('bedarfsAnmeldung.type_capacaties_changed as type_changed'),
            confirmationDialog: null,
            confirmationDialog: {
              open: null,
              title: '',
              color: 'primary-lighten-1',
              text: ''
            },
            feedbackDialog: null,
            feedbackDialog: {
              title: 'Bedarfsanmeldungen werden gesendet',
              routingPoint: '/dashboard/kita-bedarfsanmeldungen'
            },
            snackbar: {
              val: false,
              text: null,
              type: 'info'
            },
            overviewRef: null,
            newFeedbackDialog: null,
            newFeedbackDialog: {
              title: 'Anmeldung wird archiviert',
              isSending: this.$select('archive.isFetching as newFeedbackDialog.isSending')
            },
            registrationToArchive: null,
            pageLeaveDialog: null,
            next: () => {},
            helperDialog: null
        }
    },
    components: {
        Table,
        Capacity,
        FeedbackDialog,
        ConfirmationDialog,
        NewFeedbackDialog,
        PageLeaveDialog,
        HelperDialog,
        Tooltip,
        Button
    },
    watch: {
      stepperVal: function(val){
        if(val == 3){
          this.$refs.overviewRef.createChangableCharts();
        }
      }
    },

    mounted() {
        this.$dispatch(this.$actions().bedarfsAnmeldung.fetchEinrichtungen(
            () => {
              this.$dispatch(this.$actions().bedarfsAnmeldung.fetchBedarfsanmeldung(
                0, 0, this.role,() => {},
                (error) => {
                  if(error.status === 401){
                    this.$dispatch(this.$actions().user.changeLogin())
                  } else {
                    this.giveErrorFeedback();
                  }
                }));
            },
            (error) => {
              if(error.status === 401){
                this.$dispatch(this.$actions().user.changeLogin())
              } else {
                this.giveErrorFeedback();
              }
            }
        ))
    },
    beforeRouteLeave(to, from, next) {
      if(this.changed.length > 0){
          this.next = next;
          this.openPageLeaveDialog();
        } else {
          this.$dispatch(this.$actions().bedarfsAnmeldung.clearStore());
          this.$dispatch(this.$actions().archive.clearStore());
          next();
        }
    },
    methods: {
      /**
       * Gets called when the user clicks the recline registration.
       * In German: Bedarfsanmeldung zurückstellen
       *
       * @param {SyntheticEvent} event The react `SyntheticEvent`
       * @param {object} reason Containing {.id}, {.refuseReason} and {.numberOfChildren}
       * @param {string} id The .id of the registration to recline.
       * @param {string} refuseReason The reason of the reclination the user typed in.
       * @param {number} numberOfChildren  The number of childre this registration has. NumberOfChildren === registration.kindesdaten.length.
       * @public This is a public method
       */
        reclineBedarfsanmeldung(reason, id = null, refuseReason = null, numberOfChildren = 0) {
          this.$dispatch(this.$actions().bedarfsAnmeldung
            .refuseBedarfsanmeldung(reason.registrationId, reason.refuseReason, reason.numberOfChildren)).then(
              () => {
                if(reason.sendStatus !== null
                  && reason.sendStatus !== "OFFEN"){
                    this.snackbar.text = `Benachrichtigungsstatus wieder auf 'OFFEN' gesetzt.`;
                    this.snackbar.type = 'info';
                    this.snackbar.val = true;
                  }
                }
            );
        },
      /**
       * Gets called when the user clicks the undo reclination.
       * In German: Zurückstellung rückgängig machen.
       *
       * @param {SyntheticEvent} event The react `SyntheticEvent`
       * @param {string} id The .id of the registration to undo reclination.
       * @param {number} numberOfChildren The number of childre this registration has. NumberOfChildren === registration.kindesdaten.length.
       * @param {sendStatus} string The send status of the registration.
       * @public This is a public method
       */
        undoReclineBedarfsanmeldung(id, numberOfChildren, sendStatus) {
          this.$dispatch(this.$actions().bedarfsAnmeldung
            .refuseBedarfsanmeldung(id, null, numberOfChildren)).then(() => {
              if(sendStatus !== null
                  && sendStatus !== "OFFEN"){
                    this.snackbar.text = `Benachrichtigungsstatus wieder auf 'OFFEN' gesetzt.`;
                    this.snackbar.type = 'info';
                    this.snackbar.val = true;
                  }
            });
        },
      /**
       * Gets called when the user clicks the comment button
       * In German: Anmerkung hinzufügen
       *
       * @param {SyntheticEvent} event The react `SyntheticEvent`
       * @param {object} comment Containing {.registrationId} and {.refuseReason}
       * @param {string} registrationId The .id of the registration
       * @param {string} refuseReason The comment the user wants to add to this registration
       * @public This is a public method
       */
        addComment(comment, registrationId = null, refuseReason = null) {
            this.$dispatch(this.$actions().bedarfsAnmeldung
              .addComment(comment.registrationId, comment.refuseReason));
        },
      /**
       * Gets called when the user clicks the assignment Button
       * In German: An Einrichtung zuteilen
       *
       * @param {SyntheticEvent} event The react `SyntheticEvent`
       * @param {object} assigment Containing {.registrationId} and {.assignTo}
       * @param {string} registrationId The id of the registration that gets assigned
       * @param {string} assignTo The id of the facility the registration is supposed to get assigned to.
       * @public This is a public method
       */
        assignRegistration(assignment, registrationId = null, assignTo = null) {
          this.$dispatch(this.$actions().bedarfsAnmeldung
            .assignBedarfsanmeldung(assignment.registrationId, assignment.assignTo,
              assignment.numberOfChildren, assignment.undo))
              .then(() => {
                if(assignment.sendStatus !== null
                    && assignment.sendStatus !== "OFFEN"){
                      this.snackbar.text = `Benachrichtigungsstatus wieder auf 'OFFEN' gesetzt.`;
                      this.snackbar.type = 'info';
                      this.snackbar.val = true;
                    }
                if(assignment.noPrio){
                  this.$dispatch(this.$actions().bedarfsAnmeldung.updateSearchIndex(
                    assignment.registrationId, assignment.assignTo
                  ))
                }
              })
        },
        /**
         * Gets called when the user clicks the assignment Button
         * In German: Zuteilung an Einrichtung ablehnen
         *
         * @param {SyntheticEvent} event The react `SyntheticEvent`
         * @param {object} assigment Containing {.registrationId} and {.assignTo}
         * @param {string} registrationId The id of the registration that gets declined
         * @param {string} assignTo The id of the facility the registration is supposed to get declined.
         * @public This is a public method
         */
        declineRegistration(assignment, registrationId = null, assignTo = null) {
          this.$dispatch(this.$actions().bedarfsAnmeldung.declineBedarfsanmeldung(assignment.registrationId, assignment.assignTo,
            assignment.numberOfChildren))
        },
        sendBedarfsanmeldungen() {
          this.$nextTick(() => {
            this.$dispatch(this.$actions().bedarfsAnmeldung.calcChanged()).then(() => {
                this.$refs.feedbackDialog.open({bedarfsanmeldungen: this.registrationsChanged},
                  this.$actions().bedarfsAnmeldung.sendBedarfsanmeldungen);
            });
          });
        },
        sendCapacity(){
          this.stepperVal = 1;
          this.$dispatch(this.$actions().bedarfsAnmeldung.sendCapacity({
            increaseId: this.inc,
            decreaseId: this.dec
          }, this.user.csrfToken.headerName, this.user.csrfToken.token));

          let cap = [];
          this.type_changed.forEach(id => {
            let e = this.einrichtungen.find(x => x.id === id);
            if(e){
              if(e.plaetzeProEinrichtungsart !== null && e.plaetzeProEinrichtungsart.length >= 0){
                cap.push({
                  einrichtung: id,
                  plaetzeProEinrichtungsart: e.plaetzeProEinrichtungsart
                })
              }
            }
          })
          this.$dispatch(this.$actions().bedarfsAnmeldung.sendTypeCapacity([
            ...cap
          ], this.user.csrfToken.headerName, this.user.csrfToken.token))
        },
        openConfirmationDialog(title, text, confirmationAction = null){
          this.confirmationDialog.title = title;
          this.confirmationDialog.text = text;
          this.$nextTick(() => {
              this.$refs.confirmationDialog.open(confirmationAction);
          });
        },
        giveErrorFeedback(){
          this.snackbar.text = `Anmeldungen konnten nicht geladen werden.`;
          this.snackbar.type = 'error';
          this.snackbar.val = true;
        },
        archiveRegistration(registration){
          this.registrationToArchive = registration;
          this.openConfirmationDialog('Anmeldung archivieren',
          `Sie sind im Begriff diese Anmeldung zu archivieren.
          Sind Sie sich sicher, dass Sie diese Anmeldung unwiderruflich
          und dauerhaft archivieren wollen?`,
          'archiveRegistration');
        },
        confirmEventHandler(obj){
          if(obj.confirmationAction === 'sendRegistrations'){
            this.sendBedarfsanmeldungen();
          }
          if(obj.confirmationAction === 'archiveRegistration'){
            this.$nextTick(() => {
              this.$refs.newFeedbackDialog.open(this.registrationToArchive.id,
                this.$actions().archive.postArchive, false);
            });
          }
        },
        openPageLeaveDialog(){
          this.$nextTick(() => {
              this.$refs.pageLeaveDialog.open();
          });
        },
        leave(nextFunction){
          this.$dispatch(this.$actions().bedarfsAnmeldung.clearStore());
          nextFunction();
        },
        openHelperDialog(){
          this.$nextTick(() => {
              this.$refs.helperDialog.open();
          });
        },
        /**
         * Gets called when the user clicks the assign internal priority button
         * In German: Interne Priorität vergeben
         *
         * @param {SyntheticEvent} event The react `SyntheticEvent`
         * @param {string} id The id of the registration the internal priority gets assigned to
         * @param {number} prio The score of the internal priority (1 - 5 and null) 
         * @public This is a public method
          */
        assignInternalPriority(id, prio){
          this.$dispatch(this.$actions().bedarfsAnmeldung.assignInternalPriority(id, prio));
        },
        stay(){}
    }
}
</script>

<style>
.relative-toolbar{
  position:relative !important;
  transform: translateY(-100%) !important;
}
.v-app-bar-title{
  flex: none !important;
}
</style>