<template>
  <div>
    <v-autocomplete
        ref="autocomplete"
        v-model="nameSelected"
        label="Suche"
        :items="names"
        item-title="name"
        item-value="id"
        prepend-icon="mdi-magnify"
        no-data-text="Keine Anmeldung gefunden"
        append-icon="mdi-information"
        @click:append="emitExplain"
        :disabled="loading"
        clearable
        multiple
        v-model:search-input="searchInput"
        @update:modelValue="handleModelValueUpdate"
    >
        <template v-slot:item="{ item, props }">
          <v-list-item v-bind="props" :title="getName(item)" :subtitle="getSubtitle(item)" :prepend-icon="getIcon(item)" class="pb-2 pt-2">
            <template v-slot:prepend>
              <v-icon color="primary">
                {{ getIcon(item) }}
              </v-icon>
            </template>
             <template v-slot:append>
              <v-list-item-action>
                <Button icon ripple class="v-btn--round theme--light v-size--default">
                    <v-icon class="text-primary" color="primary-lighten-1" @click.stop @click="emitInfo(item.raw.registrationID)">
                    mdi-information
                    </v-icon>
                </Button>
              </v-list-item-action>
             </template>
          </v-list-item>
        </template>
        
        <!-- Chip -->
        <template v-slot:chip="{ props, item }">
          <v-chip
            v-bind="props"
            color="primary" 
            variant="flat"
            size="small"
            closable
          >
          <span class="text-white">
            <v-icon color="white" size="small" v-if="item.raw.isChild">mdi-human-child</v-icon>
            <v-icon color="white" size="small" v-if="item.raw.isParent">mdi-human-male-female</v-icon>
            {{ item.raw.firstName }} {{ item.raw.lastName }}
          </span>
          </v-chip>
        </template>
    </v-autocomplete>
  </div>
</template>


<script>
import Button from '@s/views/general_components/components/Button'

export default {
    name: "autocomplete-names",
    components: {
      Button
    },
    data() {
      return {
          nameSelected: [],
          searchInput: ''
      }
    },
    props: {
      names: {
        type: Array,
        required: true
      },
      loading: {
          type: Boolean,
          required: false
      }
    },
    watch: {
      nameSelected: function (val) {
        this.$emit('select', val)
      }
    },
    computed: {
      processedNames() {
        // Verarbeite das 'names' Prop und kombiniere 'firstName' und 'lastName'
        return this.names.map(name => ({
          ...name,
          name: `${name.firstName} ${name.lastName}`, // Kombiniere Vor- und Nachnamen
        }));
      }
    },

    methods: {
      emitExplain(){
        this.$emit('openExplain', {title: 'Suche', text: 'Nutzen Sie die Suche, um nach einer bestimmten Bedarfsanmeldung mit dem Vornamen oder Nach- und Vornnamen des Kindes oder dem Nach- und Vornamen der Eltern zu suchen. Sie können mehrere Anmeldungen auswählen.'});
      },
      emitInfo(id){
        this.$emit('openInfo', id)
      },
       handleModelValueUpdate(newValue) {
        this.searchInput = '';
      },
       getIcon(item) {
          if (item.raw.isParent) {
            return 'mdi-human-male-female';
          } else if (item.raw.isChild) {
            return 'mdi-human-child';
          }
        },
      getName(item){
        return item.raw.firstName + " " + item.raw.lastName;
      },
      getSubtitle(item){
         if (item.raw.isParent) {
            return "Vertreter von: " + item.raw.parentOf;
          } else if (item.raw.isChild) {
            return item.raw.age;
          }
      },
    }
}
</script>
