<template>
<div>
  <v-snackbar
    v-model="snackbar"
    :color="color"
    :timeout="-1"
  >
    <v-progress-circular
      color="white"
      indeterminate
      v-if="isSending"
      class="mr-4">
    </v-progress-circular>

    {{ alertText }}

    <template v-slot:action="{ attrs }">
      <Button
        v-bind="attrs"
        color="white"
        icon
        @click="snackbar = false"
      >
        <v-icon>
          mdi-minus-thick
        </v-icon>
      </Button>
    </template>
  </v-snackbar>
</div>
</template>
<script>
import Button from '@s/views/general_components/components/Button'

export default {
    name: "new-feedback-dialog",
    components: {
      Button
    },
    data() {
        return {
            snackbar: false,
            success: true,
            alertText: '',
            color: '',
            user: this.$select('user.value as user')
        }
    },
    props: {
      title: {
        type: String,
        required: true
      },
      isSending: {
        type: Boolean
      }
    },
    methods: {
        open(
          obj,
          dispatchFunction,
          emitSuccess = false
        ) {
          this.color = 'info';
          this.alertText = this.title;
          this.snackbar = true;

          if(dispatchFunction){
            this.$dispatch(dispatchFunction(obj,  this.user.csrfToken.headerName, this.user.csrfToken.token,
                () => {
                  this.color = "success"
                  this.alertText = 'Erfolgreich!';
                  if(emitSuccess){
                    this.$emit('success');
                  }
                  setTimeout(()=>{
                    this.close();
                  }, 1000)
                },
                () => {
                  this.color= "error"
                  this.alertText = 'Es ist zu einem Fehler gekommen';
                  setTimeout(()=>{
                    this.close();
                  }, 1000)
                }));
          }
        },
        close() {
            this.snackbar = false;
        }
    }
}
</script>
