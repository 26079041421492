<template>
    <div class="text-primary text--darken-1 banner">
        <v-container fill-height fluid>
            <v-row fill-height align-center justify-center>
                <v-col xs12 sm6 class="logo-container">
                    <img :src="logoKP" class="kp-logo" />
                </v-col>
                <v-col xs12 sm6 class="logo-container">
                    <img :src="logoAKDB" class="akdb-logo" />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    name: 'banner',
    data() {
        return {
            logoAKDB: require('../../assets/akdb_transparent.png'),
            logoKP: require('../../assets/kp-logo.svg'),
        };
    },
};
</script>

<style lang="stylus" scoped>
.banner {
  text-transform: uppercase;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to top, #f5f5f5, white);
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

@media screen and (min-width: 2000px) {
  .kp-logo {
    max-height: 80px;
  }

  .akdb-logo {
    max-height: 40px;
  }
}

@media screen and (max-width: 2000px) {
  .kp-logo {
    max-height: 50px;
  }

  .akdb-logo {
    max-height: 25px;
  }
}
</style>
