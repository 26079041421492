import IndexPage from './index-page'
import { Dashboard, routes as dashboardRoutes } from './dashboard'
import { createRouter, createWebHistory } from 'vue-router';

export const routes = [
    {
        path: '/',
        component: IndexPage
    }, {
        path: '/dashboard',
        component: Dashboard,
        children: dashboardRoutes
    },];

  const router = createRouter({
      history: createWebHistory(),
      routes,
  });

export default router;

