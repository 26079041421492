<template>
    <v-btn
        :color="color"
        :icon="icon"
        :disabled="disabled"
        :block="block"
        :raised="raised"
        :large="large"
        :small="small"
        :loading="loading"
        :rounded="rounded"
        :href="href"
    >
        <slot/>
    </v-btn>
</template>

<script>

export default {
    name: 'Button',
    props: {
        color: {
            type: String,
            default: undefined
        },
        icon: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        block: {
            type: Boolean,
            default: false
        },
        raised: {
            type: Boolean,
            default: false
        },
        large: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
        ripple: {
            type:[Boolean, Object],
            default: undefined
        },
        loading: {
            type: Boolean,
            default: false
        },
        rounded: {
            type: Boolean,
            default: false
        },
        dark: {
            type: Boolean,
            default: false
        },
        href: {
            type: String,
            default: undefined
        },
    },
}
</script>