<template>
  <div>
    <v-list-item
      v-for="(prop, index) in tileProps"
      :disabled="prop.disabled"
      :style="prop.backgroundColor"
      :key="index"
      class="py-2"
      lines="three"
      >
      <template v-slot:prepend>
      <v-list-item-action>
            <v-icon size="large" :color="tileProps.iconColor">
              {{ prop.iconName }}
            </v-icon>
      </v-list-item-action>
      </template>
      <div>
        <v-list-item-title>
            {{ prop.headerText }}
        </v-list-item-title>
        <v-list-item-subtitle>
            {{ prop.subheaderText }}
        </v-list-item-subtitle>
        <div v-if="!prop.disabled || prop.undoAssign" class="my-2">
          <Button
            color="success"
            @click="assignRegistration(index)"
            v-if="!prop.undoAssign && !prop.undoRecline">
            Annehmen
          </Button>
          <Button
            v-if="prop.undoAssign"
            color="success"
            @click="assignRegistration(index)">
              Zuteilung rückgängig machen
          </Button>
          <Button
            class="ml-2"
            color="error-darken-2"
            v-if="!prop.undoAssign && !prop.undoRecline"
            @click="declineRegistration(index)">
            Freigeben
          </Button>
          <Button
            v-if="prop.undoRecline"
            color="success"
            @click="declineRegistration(index)">
              Ablehnung rückgängig machen
          </Button>
        </div>
      </div>
      <template v-slot:append>
      <v-list-item-action>
         Prio {{ prop.prio + 1 }}
      </v-list-item-action>
      </template>
    </v-list-item>
    <v-divider light></v-divider>
  </div>
</template>
<script>
import Button from '@s/views/general_components/components/Button'

/**
 * Like the menu component the einrichtung action component provides functionality
 * for assignment. But for users with the role ER. This role is limited in his
 * power for assigment and therefore needs his own view.
 */
export default {
    name: "einrichtung-action-component",
    components: {
      Button
    },
    data() {
        return {}
    },
    props: {
      /**
       * The visual characteristics the menu tile is supposed to have.
       */
      tileProps: {
        type: Array,
        default: () => {
          return {
            iconName: '',
            iconColor: '',
            backgroundColor: '',
            headerText: '',
            subheaderText: '',
            prio: 1,
            disabled: false,
            prio: {
              type: Number,
              required: false
            },
            einrichtung: {}
          }
        }
      }
    },
    methods: {
        assignRegistration(index) {
        /**
          * Triggers when the assign button on the menu was clicked
          *
          * @property {object} facility The facility that was clicked on.
          * @property {boolean} noPrio True if the facility was non prioritised.
          * @property {string} role The role that permitted the action.
          */
          this.$emit('assignRegistration', {...this.tileProps[index].einrichtung}, false, 'ER');
        },
        declineRegistration(index){
        /**
          * Triggers when the decline button on the menu was clicked
          *
          * @property {object} facility The facility that was clicked on.
          */
          this.$emit('declineRegistration', {...this.tileProps[index].einrichtung});
        }
    },
    mounted(){

    }
}
</script>
