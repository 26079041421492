import {parseProp, deepProp, deepEqual, setDeep} from "./dotProp";

function bindVue(app, store, actions) {
    app.mixin({
        created() {
            //do something after creating vue instance
            if (this._bindProps) {
                const handleChange = () => {
                    this._bindProps.forEach((prop) => {
                        const {storeProp, realProp} = prop;
                        if (realProp && storeProp) {
                            const currentState = this[realProp];
                            const nextState = deepProp(store.getState(), storeProp)
                            if (!deepEqual(this[realProp], nextState)) {
                                setDeep(this, realProp, nextState)
                            }
                        }
                    })
                }
                this._unsubscribe = store.subscribe(handleChange)
            }
        },
        beforeUnmount() {
            //do something before destroying vue instance
            if (this._unsubscribe) {
                this._unsubscribe()
            }
        }
    });

    app.config.globalProperties.$select = function (prop) {
        this._bindProps = this._bindProps || [];
        prop = parseProp(prop);
        this._bindProps.push(prop);
        return deepProp(store.getState(), prop.storeProp);
    }

    app.config.globalProperties.$dispatch = function (...args) {
        return store.dispatch(...args);
    }

    app.config.globalProperties.$actions = function () {
        return actions;
    }

    app.config.globalProperties.$isEmpty = function (s) {
        return (typeof s === 'undefined' || s === null || s === '' || s === 0);
    }
}


export default class Revue {
    constructor(app, reduxStore, reduxActions) {
        this.store = reduxStore
        bindVue(app, this.store, reduxActions)
        if (reduxActions) {
            this.reduxActions = reduxActions
        }
    }

    get state() {
        return this.store.getState()
    }

    get actions() {
        return this.reduxActions
    }

    injectReducer(obj) {
        this.reduxActions[obj.key]=obj.actions
        return this.store.injectReducer(this.store, {key: obj.key, reducer: obj.reducer})
    }

    dispatch(...args) {
        return this.store.dispatch(...args)
    }
}