<template>
<div>
  <span v-if="content !== null">
    <div v-if="content === true || content === false">
      <v-icon v-if="content === true" color="success">
          check
      </v-icon>
      <v-icon v-else color="primary">
          clear
      </v-icon>
    </div>
  <span v-else>
    <div v-if="Array.isArray(content)">
        <span v-for="(c, index) in content" :key="index">
          <span v-if="index !== 0"><b>,</b> </span>
          <span v-if="typeof c === 'object'">
            <Tooltip top>
                <span class="pointer">
                  <span v-if="c.value !== null">
                    <div v-if="c.value === true || c.value === false" style="display: inline">
                      <v-icon v-if="c.value === true" color="success">
                          check
                      </v-icon>
                      <v-icon v-else color="primary">
                          clear
                      </v-icon>
                    </div>
                    <span v-else>
                      <h3
                        class="text-primary"
                        style="display: inline"
                        v-if="c.highlight">
                        {{ c.value }}
                      </h3>
                      <span v-else>{{ c.value }}</span>

                    </span>
                  </span>
                  <v-icon v-else color="primary" class="pl-2">
                      mdi-minus
                  </v-icon>
                </span>
              <template #content>
                <span v-if="c.tooltip">{{ c.tooltip }}</span>
              </template>
            </Tooltip>
          </span>
        </span>
    </div>
    <div v-if="typeof content === 'object'">
      <Tooltip top>
          <span class="pointer">
            <span v-if="content.value !== null">
              <div v-if="content.value === true || content.value === false">
                <v-icon v-if="content.value === true" color="success">
                    check
                </v-icon>
                <v-icon v-else color="primary">
                    clear
                </v-icon>
              </div>
              <span v-else>
                <span v-if="cutString">{{ cutString20(content.value) }}</span>
                <span v-else>{{ content.value }}</span>
              </span>
            </span>
            <v-icon v-else color="primary" class="pl-2">
                mdi-minus
            </v-icon>
          </span>
        <template #content>
          <span>{{ content.tooltip }}</span>
        </template>
      </Tooltip>
    </div>
    <span v-else>{{ transformDate(content) }}</span>
    </span>
  </span>
  <v-icon v-else color="primary" class="pl-2">
      mdi-minus
  </v-icon>
</div>
</template>
<script>
import Tooltip from '@s/views/general_components/components/Tooltip'
import { cutString20, transformDate } from '@s/util/helper'

/**
 * Table col is the smallest component. Providing certain functionalities for
 * displaying the data in the cols of the table. Rendering objects and arrays the
 * right way. Providing icons for bollean values and so on.
 */
export default {
    name: "table-col",
    components: {
      Tooltip
    },
    props: {
      /**
       * The content of the column that is supposed to get displayed.
       */
        content: [String, Number, Boolean, Object, Array],
        cutString: {
          type: Boolean,
          default: false 
        }

    },
    methods: {
      cutString20,
      transformDate,
    }
}
</script>
