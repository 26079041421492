<template>
<v-dialog v-model="dialog" max-width="80vw">
    <v-card>
        <v-card-title class="text-h5 bg-primary-lighten-1 align-center d-flex justify-space-between" primary-title>
          <div>
              <v-icon size="large" style="color: white" class="pr-3">
                  mdi-information
              </v-icon>
              <span style="color: white">
                Filter-Kriterien festlegen
            </span>
          </div>
            <Button large class="close-button" icon @click="close">
                <v-icon color="white">
                    mdi-close
                </v-icon>
            </Button>
        </v-card-title>
        <v-card-text>
            <h4 class="pt-4">Nach Merkmalen filtern:</h4>
            <v-chip-group
            v-model="specialFeaturesSelected"
            multiple
            column
            >
              <v-chip
                color="primary"
                filter
                variant="outlined"
                v-for="(item, key, index) in specialFeatures" :key="index"
              >
                {{ item.label }}
              </v-chip>
            </v-chip-group>

            <v-divider class="mt-4" />
            
            <h4 class="pt-4">Nach Einrichtungsart filtern:</h4>
            <v-select
              v-model="einrichtungsartSelected"
              :items="einrichtungsarten"
              item-title="name"
              item-value="id"
              label="Einrichtungsarten auswählen"
              clearable
              single-line
              multiple
              chips
            >
            </v-select>

            <v-divider class="pb-4" />
 
                <h4 style="display: inline">Nach Alter filtern: </h4>
                <Tooltip top style="display: inline">
                  <v-icon size="small" class="pointer" color="grey">mdi-information</v-icon>
                  <template #content>
                    <span>Kindesalter zum Zeitpunkt des Aufnahmedatums</span>
                  </template>
                </Tooltip>
                <v-checkbox
                  :label="`nach Alter filtern`"
                  v-model="filterByAge"
                  class="pb-0 pt-0"
                  color="primary"
                ></v-checkbox>
             
              
                  <h4>Minimales Alter:</h4>
                  <v-slider 
                    color="primary" 
                    class="mt-0 ml-2" 
                    :disabled="!filterByAge" 
                    v-model="minAge" 
                    min="0" 
                    max="12" 
                    thumb-label 
                    :label="`Jahre: ${minAge}`" 
                    prepend-icon="mdi-calendar"
                    step="1"
                  />
                  <v-slider
                    color="primary-lighten-2"
                    class="mt-0 ml-2"
                    :disabled="!filterByAge"
                    v-model="minMonth"
                    min="0"
                    max="11"
                    thumb-label
                    prepend-icon="mdi-calendar" 
                    :label="`Monate: ${minMonth}`"
                    step="1"
                  />
           
                  <h4>Maximales Alter:</h4>
                  <v-slider 
                    color="primary" 
                    class="mt-0 ml-2"
                    :disabled="!filterByAge" 
                    v-model="maxAge" 
                    min="0" 
                    max="12" 
                    thumb-label 
                    :label="`Jahre: ${maxAge}`" 
                    prepend-icon="mdi-calendar"
                    step="1"
                  />
                  <v-slider 
                    color="primary-lighten-2" 
                    class="mt-0 ml-2" 
                    :disabled="!filterByAge" 
                    v-model="maxMonth" 
                    min="0" 
                    max="11" 
                    thumb-label 
                    :label="`Monate: ${maxMonth}`" 
                    prepend-icon="mdi-calendar"
                    step="1"
                  />

            <v-divider />
            <h4 class="pt-4">Nach Status filtern:</h4>
            <div style="display: flex">
              <div v-for="(item, key, index) in registrationStatus" :key="index">
                <v-switch
                   :label="item.label"
                   v-model="item.value"
                   color="primary"
                   class="px-4"
                 ></v-switch>
              </div>
            </div>
            <v-divider />
            <div v-for="(kita, index) in einrichtungenSelected" :key="index">
                <h4 class="pt-2">{{ kita.name }}</h4>
                <v-switch
                   v-model="einrichtungenSelected[index].chooseSpecificPrio"
                   :label="`Nach spezifischer Priorität filtern`"
                   color="primary"
                 ></v-switch>
                 <h4 class="text-primary"  v-if="kita.chooseSpecificPrio">
                   Spezifische Priorität: Es werden nur die Anmeldungen angezeigt,
                   bei der die Einrichtung mit der spezifizierten Priorität von
                   den Erziehungsberechtigten priorisiert wurde.
                 </h4>
                 <div style="display: flex" v-if="kita.chooseSpecificPrio">
                   <div v-for="(p, i) in kita.prios" :key="i">
                     <v-switch
                        :label="`Priorität ${i +1}`"
                        v-model="einrichtungenSelected[index].prios[i]"
                        class="px-4"
                        color="primary"
                      ></v-switch>
                   </div>
                 </div>
                <v-divider />
            </div>
         </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <Button
              raised
              variant="flat"
              color="primary"
              @click="confirm">
                Mit diesen Einstellungen filtern
            </Button>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>
<script>
import Tooltip from '@s/views/general_components/components/Tooltip'
import Button from '@s/views/general_components/components/Button'

export default {
    name: "filter-dialog",
    components: {
      Tooltip,
      Button
    },
    data() {
        return {
            dialog: false,
            einrichtungsarten: this.$select('general.einrichtungsarten as einrichtungsarten'),
            einrichtungsartSelected: [],
            einrichtungenSelected: [],
            filterByAge: false,
            minAge: 0,
            maxAge: 1,
            minMonth: 0,
            maxMonth: 1,
            specialFeaturesSelected: [],
            specialFeatures: [{
              label: 'Integrationsbedarf',
              value: false,
              searchString: 'INTEGRATIONSBEDARF'
            }, {
              label: 'Bemerkung vorhanden',
              value: false,
              searchString: 'BEMERKUNG'
            }, {
              label: 'Unterjährig',
              value: false,
              searchString: 'UNTERJAEHRIG'
            }, {
              label: 'Alleinerziehend',
              value: false,
              searchString: 'SINGLEPARENT'
            }, {
              label: 'Geschwisterkind',
              value: false,
              searchString: 'SIBLINGS'
            }, {
              label: 'Alle KBs haben abgelehnt',
              value: false,
              searchString: 'ALLREJECTED'
            }, {
              label: 'Alternatives Aufnahmedatum',
              value: false,
              searchString: 'ALTERNATIVEDATE'
            }, {
              label: 'Interne Dringlichkeit',
              value: false,
              searchString: 'INTERNALPRIO'
            }, {
              label: 'Sie sind mit der Zuteilung dran',
              value: false,
              searchString: 'FACILITYROLEMUSTACT'
            }, {
              label: 'Alle Vertreter sind berufstätig',
              value: false,
              searchString: 'ALL_VERTRETER_BERUFSTAETIG'
            }, {
              label: 'Mindestens ein Vertreter ist berufstätig',
              value: false,
              searchString: 'AT_LEAST_ONE_VERTRETER_BERUFSTAETIG'
            }, {
              label: 'Neuer Hauptwohnsitz',
              value: false,
              searchString: 'AT_LEAST_ONE_NEUER_HAUPTWOHNSITZ'
            }],
            registrationStatus: [{
              label: 'Offen',
              value: false,
              searchString: 'OFFEN'
            }, {
              label: 'Genehmigt',
              value: false,
              searchString: 'GENEHMIGT'
            }, {
              label: 'Zurückgestellt',
              value: false,
              searchString: 'ZURUECKGESTELLT'
            }]
        }
    },
    watch: {
      dialog(val) {
        val || this.close();
      },
      minAge(newAge){
        if(newAge > this.maxAge){
          this.maxAge = newAge + 1;
        }
      },
      maxAge(newAge){
        if(newAge < this.minAge){
          this.minAge = newAge - 1;
        }
      },
      minMonth(newMonth){
        if(this.minAge === this.maxAge){
          if(newMonth > this.maxMonth){
            this.maxMonth = newMonth + 1;
          }
        }
      },
      maxMonth(newMonth){
        if(this.minAge === this.maxAge){
          if(newMonth < this.minMonth){
            this.minMonth = newMonth - 1;
          }
        }
      }
    },
    methods: {
      open(einrichtungenSelected, reset = false) {
        this.einrichtungenSelected = [];
        einrichtungenSelected.forEach(einrichtung => {
          let prios = [false, false, false, false, false];
          this.einrichtungenSelected.push({
            id: einrichtung.id,
            name: einrichtung.name,
            chooseSpecificPrio: false,
            prios: prios})
        })
        if(reset){
          this.specialFeaturesSelected = [];
          this.specialFeatures.forEach(item => {
            item.value = false;
          });
          this.filterByAge = false;
          this.minAge = 0;
          this.maxAge = 1;
          this.registrationStatus.forEach(item => {
            item.value = false;
          })
          this.einrichtungsartSelected = [];
        }
        this.dialog = true;
      },
      close() {
          this.dialog = false;
      },
      confirm() {
        let returnString = '';
        this.einrichtungenSelected.forEach(kita => {
          if(kita.chooseSpecificPrio){
            for(let i = 0; i < kita.prios.length; ++i){
              if(kita.prios[i]){
                returnString += `${kita.id}-PRIO:${ i + 1}, `
              }
            }
          } else {
            returnString += `${kita.id}, `
          }
        })
        this.specialFeaturesSelected.forEach(index => {
          returnString += `${this.specialFeatures[index].searchString}, `
        })
        this.registrationStatus.forEach(status => {
          if(status.value){
            returnString += `${status.searchString}, `
          }
        })
        if(this.filterByAge){
          if(this.minAge <= this.maxAge){
            for(let i = this.minAge * 12 + this.minMonth; i <= this.maxAge * 12 + this.maxMonth; ++i){
              returnString += `$$AGE-${i}, `
            }
          }
        }
        if(this.einrichtungsartSelected.length > 0){
          returnString += `EINRICHTUNGSART-${this.einrichtungsartSelected}, `
        }
        this.$emit('returnSearchString', returnString);
        this.close();
      }
    }
}
</script>
