<template>
    <v-tooltip
        :activator="hasContentSlot ? undefined : $parent"
        :bottom="bottom"
        :left="left"
        :right="right"
        :top="top"
    >
       <!--<template v-if="hasContentSlot" v-slot:activator="{ on, attrs }">
            <span v-on="on">
                <slot v-bind="attrs"/>
            </span>
        </template>-->

        <template v-if="hasContentSlot" v-slot:activator="{ props }">
          <span v-bind="props">
              <slot name="default"/>
          </span>
      </template>


        <slot :name="hasContentSlot ? 'content' : 'default'"/>

    </v-tooltip>
</template>

<script>

export default {
    name: 'Tooltip',
    computed: {
        hasContentSlot() {
            return !!this.$slots.content;
        }
    },
    props: {
        bottom: {
            type: Boolean,
            default: false
        },
        top: {
            type: Boolean,
            default: false
        },
        right: {
            type: Boolean,
            default: false
        },
        left: {
            type: Boolean,
            default: false
        },
    },
}
</script>
