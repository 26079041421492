<template>
<div>
    <v-row>
        <v-col md="12" lg="6" offset-lg="3" class="pt-4">
          <v-app-bar color="primary" class="relative-toolbar">
            <v-app-bar-nav-icon>
                <v-icon color="white">mdi-help-circle</v-icon>
            </v-app-bar-nav-icon>
            <v-app-bar-title class="text-white">FAQ Kitaplatz - Pilot</v-app-bar-title>
            <v-spacer></v-spacer>
          </v-app-bar>
          <v-card class="px-4">
            <div class="py-5" align="left">
              <v-alert
                variant="outlined"
                color="black"
                icon="mdi-alert"
              >
                <div class="text-h6">
                  Hinweis
                </div>
                <div>Bitte beachten Sie, dass die Vergabe der Plätze entsprechend der gesetzlichen Grundlagen erfolgen muss.</div>
              </v-alert>
              <span class="text-h6"><b></b></span>
            </div>
            <div align="left">
                <h4>Wo sind die eingegangenen Bedarfs-Anmeldungen zu finden?</h4>
                <p class="pt-0">Unter dem Menüpunkt <i>Kita Bedarfsanmeldungen</i> finden Sie alle
                  eingegangen Bedarfsanmeldungen. <a class="text-primary" @click="navigate('/dashboard/kita-bedarfsanmeldungen')">Zu den Bedarfsanmeldungen</a>.</p>
            </div>
            <hr class="mb-4">
            <div align="left">
                <h4>Wie können die Bedarfsanmeldungen zugeteilt werden?</h4>
                <p class=" pb-0 pt-0">Abhängig von Ihrer Nutzer-Rolle haben Sie unterschiedliche Zuteilungsrechte.</p>
                <div align="center" class="px-5">
                    <table>
                        <tr>
                            <th>Rolle</th>
                            <th>Erklärung</th>
                        </tr>
                        <tr>
                            <td>Kommune</td>
                            <td>Sie können die Bedarfsanmeldung an jede Einrichtung ihrer Kommune zuteilen.
                              Sie müssen keine Rücksicht auf die Priorisierung der Eltern nehmen.</td>
                        </tr>
                        <tr>
                            <td>Träger</td>
                            <td>Sie können die Bedarfsanmeldung an jede Einrichtung zuteilen von der Sie Träger sind.
                            Sie müssen keine Rücksicht auf die Priorisierung der Eltern nehmen.</td>
                        </tr>
                        <tr>
                            <td>Einrichtung</td>
                            <td>Sie haben die Möglichkeit eine Bedarfsanmeldung anzunehmen.
                            Sie müssen Rücksicht auf die Priorisierung der Eltern nehmen. Eine Annahme ist nur dann möglich, wenn alle Einrichtungen
                            mit einer höheren Priorisierung als Sie abgelehnt haben. Sollten sie annehmen, kann keine Einrichtung mit einer niedrigeren
                            Priorisierung als Sie mehr annehmen.
                            </td>
                        </tr>
                    </table>
                </div>
                <p class="">Eine Zuteilung lässt sich durch einen Klick auf den Zuteilung-Button
                  (<v-icon color="primary" size="small">mdi-dots-horizontal</v-icon>) durchgeführen.
                  Um die von Ihnen getätigten Zuteilungen permanent zu speichern, klicken sie auf <i>Übersicht</i> und dann auf <i>Änderungen speichern</i>.</p>
            </div>
            <hr class="mb-4">
            <div align="left">
                <h4>Was bedeutet der <i>Status</i> einer Bedarfsanmeldung?</h4>
                <p class="pb-0 pt-0">Eine Bedarfsanmeldung kann unterschiedliche Status annehmen. Diese sind:</p>
                <div align="center" class="px-5">
                    <table>
                        <tr>
                            <th>Status</th>
                            <th>Erklärung</th>
                        </tr>
                        <tr>
                            <td>OFFEN</td>
                            <td>Das Kind wurde noch keiner Einrichtung zugeteilt.</td>
                        </tr>
                        <tr>
                            <td>GENEHMIGT</td>
                            <td>Das Kind wurde einer Einrichtung zugeteilt.</td>
                        </tr>
                        <tr>
                            <td>ZURÜCKGESTELLT</td>
                            <td>
                              Das Kind wurde mit entsprechender Begründung zunächst zurückgestellt und ist keiner
                              Einrichtung zugeteilt. Dies macht Sinn, wenn Personensorgeberechtigte den Bedarf zurückziehen
                              oder Personensorgeberechtigte den angebotenen Platz ablehnen.
                            </td>
                        </tr>
                    </table>
                </div>
                <p class="">Eine <i>Zurückstellung</i> kann nur von Ihrer Kommune durchgeführt werden, für diesen Fall kontaktieren Sie Ihre Kommune.</p>
            </div>
            <hr class="mb-4">
            <div align="left">
                <h4>Wo sind die vollständigen Angaben zu einem Kind zu finden?</h4>
                <p class=" pt-0">
                  Am Ende jeder Zeile eines Kindes befindet sich ein Informationsbutton (<v-icon color="primary" size="small">mdi-information</v-icon>).
                  Hier können Sie sich alle Angaben zum Kind und der Personensorgeberechtigten
                  unverändert anzeigen lassen, wie diese erfasst und per PDF-Dokument bestätigt worden sind.
                  Hier können Sie auch die <i>Bemerkungen</i> einsehen, welche hinzugefügt wurden.
                </p>
            </div>
            <hr class="mb-4">
            <div align="left">
                <h4>Wie wird ersichtlich, ob eine weitere Zuteilung zu der Einrichtung aufgrund der Platz-Kapazität möglich ist?</h4>
                <p class=" pt-0">
                  Durch einen Klick auf den Reiter <i>Auslastung</i> können Sie feststellen,
                  welche Kapazitäten in der jeweiligen Einrichtung noch zur Verfügung stehen.
                  Eine Zuteilung bei Überbelegung ist möglich.
                </p>
            </div>
            <hr class="mb-4">
            <div align="left">
                <h4>Wie können die Stammdaten zur Einrichtung/Träger verändert werden?</h4>
                <p class=" pt-0">
                  Unter dem Menüpunkt <i>Adminbereich</i> haben Sie, je nach Berechtigung die Möglichkeit
                  Daten zu verändern.
                </p>
            </div>
            <hr class="mb-4">
            <div align="left">
                <h4>Wie kommen die gespeicherten Zuteilungen in das Kitaverwaltungs-Programm?</h4>
                <p class=" pt-0">
                  Am Ende des Verteilprozesses steht der <i>Export</i> der Daten je
                  Betreuungseinrichtung an. Sie können nun eine Datei erstellen,
                  welche in ihr jeweiliges Kitaverwaltungsprogramm eingelesen werden kann.
                  In adebisKITA ist diese Funktion vorgesehen, für den Import in andere
                  Kita-Verwaltungsprogramm können wir Ihnen gerne eine Daten-/Satzbeschreibung
                  zur Verfügung stellen (Kundenportal adebisKITA), die Ihr Softwarelieferant
                  als Grundlage für einen Import benötigt. <a @click="navigate('/dashboard/zugeteilte-bedarfsanmeldungen-export')">Zu dem Export</a>.
                </p>
            </div>
            <hr class="mb-4">
            <div align="center" class="mb-4">
              <p class="">Bei weiteren Fragen oder Problemen können Sie sich
                gerne an unsere kostenlose Service-Hotline unter der Telefonnumer
                <i>0800 2553222-83</i> oder per E-Mail an <i>adebisKITA@akdb.de</i> wenden.
              </p>
            </div>
          </v-card>
          <Tooltip bottom>
            <template #default="attrs">
              <Button 
                v-bind="attrs" 
                color="primary" 
                :href="pdfLink" 
                download="faq.pdf" 
                block class="mb-5" 
              >
                  FAQ herunterladen
              </Button>
            </template>
            <template #content>
              <span>
                  FAQs als PDF herunterladen
              </span>
            </template>
          </Tooltip>
        </v-col>
    </v-row>

</div>
</template>
<script>
import Tooltip from '@s/views/general_components/components/Tooltip'
import Button from '@s/views/general_components/components/Button'

export default {
    name: "verwaltung",
    components: {
      Tooltip,
      Button
    },
    data() {
        return {
          pdfLink: require("../../../assets/faq.pdf"),
        }
    },
    methods: {
        navigate(link) {
            this.$router.replace(link);
        }
    }
}
</script>

<style lang="stylus" scoped>
    i
      font-weight: bold

    td, th
      border: 1px solid #dddddd
      text-align: left
      padding: 8px

    tr:nth-child(even)
      background-color: #dddddd

</style>
