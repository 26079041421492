<template>
<v-dialog v-model="dialog" width="600">
    <v-card>
      <v-card-title class="text-h5 bg-primary d-flex justify-space-between" :class="backgroundColorClass" primary-title>
        <div class="align-center">
            <v-icon size="large" style="color: white" class="pr-3">
              {{ icon }}
            </v-icon>
            <span style="color: white">
              {{ title }}
            </span>
        </div>
          <Button class="close-button" large icon @click="closeDialog">
              <v-icon color="white">
                  mdi-close
              </v-icon>
          </Button>
      </v-card-title>
      <v-card-text class="mt-8">
          <v-alert
            variant="outlined"
            text
            :value="true"
            :type="alertType"
          >
            {{ text }}
          </v-alert>
      </v-card-text>
      <v-card-actions>
          <v-spacer></v-spacer>
          <Button variant="flat" :color="color" @click="confirm">
              {{ buttonText }}
          </Button>
      </v-card-actions>
    </v-card>
</v-dialog>
</template>
<script>
import Button from '@s/views/general_components/components/Button'

export default {
    name: "confirmation-dialog",
    data() {
        return {
            dialog: false,
            action: null
        }
    },
    props: {
      icon: {
        type: String,
        default: 'mdi-information'
      },
      title: {
        type: String,
        required: true
      },
      color: {
        type: String,
        required: true
      },
      text: {
        type: String,
        required: true
      },
      alertType: {
        type: String,
        default: 'info'
      },
      buttonText: {
        type: String,
        default: 'Akzeptieren'
      }
    },
    watch: {
      dialog(val) {
        val || this.closeDialog();
      }
    },
    computed: {
      backgroundColorClass() {
        return `bg-${this.color}`; // Fügt 'bg-' vor dem Wert von `color` hinzu
      },
    },
    methods: {
        open(action = null) {
          this.action = action;
          this.dialog = true;
        },
        closeDialog() {
          this.dialog = false;
          this.action = null;
        },
        confirm(){
          this.$emit('confirm', { confirmationAction: this.action });
          this.dialog = false
        }
    },
    components: {
      Button
    }
}
</script>
