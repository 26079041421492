<template>
    <div class="dashboard">
        <v-snackbar v-model="snackbar" :color="snackbarColor">
            <v-icon color="white"> mdi-alert </v-icon>
            {{ snackbarText }}
            <Button color="white" @click="snackbar = false" icon>
                <v-icon> mdi-close-circle </v-icon>
            </Button>
        </v-snackbar>
        <v-navigation-drawer v-model="drawer" position="fixed" temporary style="width: fit-content">
            <v-list>
                <v-list-item exact to="/dashboard">
                    <v-list-item-title><v-icon color="grey-darken-1" class="mr-8">mdi-help-circle</v-icon>Kurzbeschreibung</v-list-item-title>
                </v-list-item>
                <v-list-item exact to="/dashboard/aenderungs-uebersicht">
                        <v-list-item-title><v-icon color="grey-darken-1" class="mr-8">mdi-new-box</v-icon>Neuigkeiten</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-subheader class="pt-4 pb-2">
                    <h3>Zuteilung</h3>
                </v-list-subheader>
                <v-list-item to="/dashboard/kita-bedarfsanmeldungen">
                    <v-list-item-action>
                        <v-list-item-title><v-icon color="grey-darken-1" class="mr-8">mdi-note-multiple-outline</v-icon>Bedarfsanmeldungen</v-list-item-title>
                    </v-list-item-action>
                </v-list-item>
                <v-list-item to="/dashboard/anmeldungen/statistik">
                    <v-list-item-title><v-icon color="grey-darken-1" class="mr-8">mdi-elevation-rise</v-icon>Statistik</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-subheader class="pt-4 pb-2">
                    <h3>Post Zuteilung</h3>
                </v-list-subheader>
                <v-list-item
                    to="/dashboard/eltern-benachrichtigungen"
                    v-if="
                        permissions['Postkorbnachricht.send'].includes(
                            'lesen / anzeigen'
                        )
                    "
                >
                    <v-list-item-title><v-icon color="grey-darken-1" class="mr-8">mdi-email</v-icon>Benachrichtigungen</v-list-item-title>
                </v-list-item>
                <v-list-item
                    to="/dashboard/bestaetigen"
                    v-if="
                        permissions['Bestaetigung.save'].includes(
                            'lesen / anzeigen'
                        )
                    "
                >
                    <v-list-item-title><v-icon color="grey-darken-1" class="mr-8">mdi-check-circle</v-icon>Rücklauf</v-list-item-title>
                </v-list-item>
                <v-list-item
                    to="/dashboard/zugeteilte-bedarfsanmeldungen-export"
                >
                    <v-list-item-title><v-icon color="grey-darken-1" class="mr-8">mdi-cloud-download</v-icon>Export</v-list-item-title>
                </v-list-item>
                <v-list-item to="/dashboard/abgeschlossene-anmeldungen">
                    <v-list-item-title><v-icon color="grey-darken-1" class="mr-8">mdi-clipboard-check</v-icon>Abgeschlossen</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-subheader
                    class="pt-4 pb-2"
                    v-if="['Superadmin', 'KV'].includes(user.role)"
                >
                    <h3>Archiv Funktionalitäten</h3>
                </v-list-subheader>
                <v-list-item
                    to="/dashboard/archiv"
                    v-if="['Superadmin', 'KV'].includes(user.role)"
                >
                   <v-list-item-title><v-icon color="grey-darken-1" class="mr-8">mdi-archive</v-icon>Archiv einsehen</v-list-item-title>
                </v-list-item>
                <v-list-item
                    to="/dashboard/archiv-einleiten"
                    v-if="['Superadmin', 'KV'].includes(user.role)"
                >
                   <v-list-item-title><v-icon color="grey-darken-1" class="mr-8">mdi-archive-plus</v-icon>Archivierung einleiten</v-list-item-title>
                </v-list-item>
                <v-list-item
                    to="/dashboard/archiv-wiedereinsetzen"
                    v-if="['Superadmin', 'KV'].includes(user.role)"
                >
                   <v-list-item-title><v-icon color="grey-darken-1" class="mr-8">mdi-archive-refresh</v-icon>Archivierung wiedereinsetzen</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-subheader class="pt-4 pb-2">
                    <h3>Admin Bereich</h3>
                </v-list-subheader>
                <v-list-item
                    to="/dashboard/traeger"
                    v-if="permissions.Traeger.includes('lesen / anzeigen')"
                >
                    <v-list-item-title><v-icon color="grey-darken-1" class="mr-8">mdi-cog</v-icon>Träger</v-list-item-title>
                </v-list-item>
                <v-list-item
                    to="/dashboard/einrichtungsarten"
                    v-if="
                        permissions.Einrichtungsart.includes('lesen / anzeigen')
                    "
                >
                    <v-list-item-title><v-icon color="grey-darken-1" class="mr-8">mdi-cog</v-icon>Einrichtungsarten</v-list-item-title>
                </v-list-item>
                <v-list-item
                    to="/dashboard/be-detailpflege"
                    v-if="permissions.Einrichtung.includes('lesen / anzeigen')"
                >
                    <v-list-item-title><v-icon color="grey-darken-1" class="mr-8">mdi-cog</v-icon>Einrichtungen</v-list-item-title>
                </v-list-item>
                <v-list-item
                    to="/dashboard/einrichtungen-bilder"
                    v-if="
                        permissions['Einrichtung.images'].includes(
                            'lesen / anzeigen'
                        )
                    "
                >
                    <v-list-item-title><v-icon color="grey-darken-1" class="mr-8">mdi-image</v-icon>Einrichtungen - Bilder</v-list-item-title>
                </v-list-item>
                <v-list-item
                    to="/dashboard/saisonparameter"
                    v-if="
                        permissions.Saisonparameter.includes('lesen / anzeigen')
                    "
                >
                    <v-list-item-title><v-icon color="grey-darken-1" class="mr-8">mdi-cog</v-icon>Saisonparameter</v-list-item-title>
                </v-list-item>
                <v-list-item
                    to="/dashboard/mandantparameter"
                    v-if="
                        permissions.Mandantparameter.includes(
                            'lesen / anzeigen'
                        )
                    "
                >
                    <v-list-item-title><v-icon color="grey-darken-1" class="mr-8">mdi-cog</v-icon>Mandatparameter</v-list-item-title>
                </v-list-item>
                <v-list-item to="/dashboard/export">
                    <v-list-item-title><v-icon color="grey-darken-1" class="mr-8">mdi-cloud-download</v-icon>Admin - Export</v-list-item-title>
                </v-list-item>
                <v-list-item
                    to="/dashboard/kennzahlen"
                    v-if="['Superadmin'].includes(user.role)"
                >
                    <v-list-item-title><v-icon color="grey-darken-1" class="mr-8">mdi-chart-box</v-icon>Kennzahlen</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-subheader class="pt-4 pb-2">
                    <h3>Benutzerkonto</h3>
                </v-list-subheader>
                <v-list-item to="/dashboard/benutzerkonto">
                    <v-list-item-title><v-icon color="grey-darken-1" class="mr-8">mdi-account-circle</v-icon>Kontoinformationen</v-list-item-title>
                </v-list-item>
                <v-list-item @click="navigateUserManagement">
                    <v-list-item-title><v-icon color="grey-darken-1" class="mr-8">mdi-link-variant</v-icon>Zur Kontoverwaltung</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-subheader
                    class="pt-4 pb-2"
                    v-if="['Superadmin', 'KV'].includes(user.role)"
                >
                    <h3>Verschiedenes</h3>
                </v-list-subheader>
                <v-list-item
                    to="/dashboard/change-log"
                    v-if="['Superadmin', 'KV'].includes(user.role)"
                >
                    <v-list-item-title><v-icon color="grey-darken-1" class="mr-8">mdi-file-arrow-left-right</v-icon>Change Log</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-subheader
                    class="pt-4 pb-2"
                    v-if="['Superadmin'].includes(user.role)"
                >
                    <h3>Testbereich</h3>
                </v-list-subheader>
                <v-list-item
                    to="/dashboard/bedarfsanmeldung-erstellung"
                    v-if="['Superadmin'].includes(user.role)"
                >
                    <v-list-item-title><v-icon color="grey-darken-1" class="mr-8">mdi-plus</v-icon>Anmeldung Erstellung</v-list-item-title>
                </v-list-item>
            </v-list>
            <v-list density="compact">
                <v-list-item @click="logout">
                    <v-list-item-title><v-icon color="grey-darken-1" class="mr-8">mdi-logout</v-icon>Abmelden</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <v-app-bar
            :class="{'app-bar-clipped-left': $vuetify.display.large}"
            color="primary"
            position="fixed"
        >
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"
                ><v-icon>mdi-menu</v-icon></v-app-bar-nav-icon
            >
            <v-app-bar-title
                ><img :src="logoKP" class="kp-logo"
            /></v-app-bar-title>
            <v-spacer></v-spacer>
            <Tooltip bottom>
                <template #default="attrs">
                    <span v-bind="attrs" class="pointer pr-2">
                        <v-icon size="small">mdi-timer</v-icon>
                        {{ displayTimer(timer) }}
                    </span>
                </template>
                <template #content>
                    <span>Zeit bis zum Logout</span>
                </template>
            </Tooltip>
            <div>
                <span> {{ user.value.username }} </span> -
                <span>{{ date }}</span>
                <Tooltip bottom>
                    <template #default="attrs">
                        <v-btn v-bind="attrs" icon @click="logout" class="mx-4">
                            <v-icon>mdi-logout</v-icon>
                        </v-btn>
                    </template>
                    <template #content>
                        <span>Abmelden</span>
                    </template>
                </Tooltip>
            </div>
        </v-app-bar>
        <v-container fluid class="fill-height">
            <v-row align-center justify-center>
                <v-col class="text-center">
                    <router-view class="ma-15"></router-view>
                </v-col>
            </v-row>
        </v-container>
        <v-footer color="primary" app class="fullwidth" style="justify-content:center">
            <div class="text-white"
                >All rights reserved &copy; 2023 | Version: v3.0.4</div
            >
        </v-footer>
    </div>
</template>
<script>
import { transformDate, formatDate, displayTimer } from '../../util/helper';
import Tooltip from '@s/views/general_components/components/Tooltip';
import Button from '@s/views/general_components/components/Button';

export default {
    name: 'dashboard',
    components: {
        Tooltip,
        Button,
    },
    data() {
        return {
            drawer: false,
            fullname: this.$select('user.value.fullname as fullname'),
            userIsFetching: this.$select('user.isFetching as userIsFetching'),
            userIsFetched: this.$select('user.fetched as userIsFetched'),
            user: this.$select('user as user'),
            date: null,
            permissions: this.$select('user.permissions as permissions'),
            loggedIn: this.$select('user.loggedIn as loggedIn'),
            snackbar: false,
            snackbarText: null,
            snackbarColor: 'error',
            preLink: 'https://kpba-pre.akdb.de/openam/',
            prodLink: 'https://kpba.akdb.de/openam/',
            timer: 7200,
            logoKP: require('../../assets/kp-logo-white.svg'),
        };
    },
    watch: {
        loggedIn: function (val) {
            if (!val) {
                this.snackbarText = `Session abgelaufen`;
                this.snackbar = true;
                this.$dispatch(this.$actions().auth.newLogout()).then(() => {
                    this.$dispatch(this.$actions().user.clearStore()).then(
                        () => {
                            setTimeout(() => {
                                this.$router.push('/');
                            }, 3000);
                        }
                    );
                });
            }
        },
    },
    methods: {
        logout() {
            localStorage.removeItem('timer');
            localStorage.removeItem('date');
            this.$dispatch(this.$actions().auth.newLogout()).then(() => {
                let x = this.user.value.csrfToken.parameterName;
                this.postwith('/user/logout', {
                    [x]: this.user.value.csrfToken.token,
                });
            });
        },
        postwith(to, p) {
            let myForm = document.createElement('form');
            myForm.method = 'post';
            myForm.action = to;
            for (var k in p) {
                var myInput = document.createElement('input');
                myInput.setAttribute('name', k);
                myInput.setAttribute('value', p[k]);
                myForm.appendChild(myInput);
            }
            document.body.appendChild(myForm);
            myForm.submit();
            document.body.removeChild(myForm);
        },
        navigateUserManagement() {
            let currentLocation = window.location.href;

            if (currentLocation.includes('-pre.akdb')) {
                const new_win = window.open(this.preLink, '_blank');
                new_win.focus();
            } else {
                const new_win = window.open(this.prodLink, '_blank');
                new_win.focus();
            }
        },
        saveTimer(timer) {
            const seconds = new Date().getTime() / 1000;
            localStorage.setItem('date', seconds);
            localStorage.setItem('timer', timer);
        },
        transformDate,
        formatDate,
        displayTimer,
    },
    created() {
        let date = localStorage.getItem('date');
        let timer = localStorage.getItem('timer');

        if (date !== null && timer !== null) {
            let dateNow = new Date().getTime() / 1000;
            let difference = dateNow - date;
            timer = Math.round(timer - difference);
            if (timer > 0) {
                this.timer = timer;
            }
        }

        this.$dispatch(
            this.$actions().general.fetchEinrichtungsarten(
                () => {},
                (error) => {
                    if (error.status === 401) {
                        this.$dispatch(this.$actions().user.changeLogin());
                    }
                }
            )
        );
        this.date = this.formatDate(new Date());

        // Does not work --> Request is not guaranteed to reach server
        //window.addEventListener('beforeunload', this.logout);
        window.setInterval(() => {
            this.timer--;
            if (this.timer <= 0) {
                this.logout();
            }
        }, 1000);
        this.saveTimer(this.timer);
    },
};
</script>

<style lang="stylus" scoped>
.kp-logo
    width 90px
    border-radius 5px
    border 2px solid transparent
    align-items center
    justify-content center
    margin-top 8px

.app-bar-clipped-left {
    margin-left: 256px;
}
</style>
