<template>
    <v-expansion-panels>
        <v-expansion-panel>
            <v-expansion-panel-title color="primary">
                <span class="text-h6 text-white"
                    >{{ kindesdaten.vorname }} {{ kindesdaten.name }} (geb. {{ transformDate(kindesdaten.geburtsdatum) }})
                </span>
                <template v-slot:actions>
                    <v-icon color="white" size="large">mdi-chevron-down</v-icon>
                </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text class="mt-6">
                <v-row class="mt-6 mb-4">
                    <v-col cols="6">
                        <!--  Steckbrief -->
                        <v-card
                            class="border rounded-lg mx-10 pt-2"
                            style="
                                border: 1px solid lightgray;
                                box-shadow: none;
                            "
                        >
                            <div style="display: flex; align-items: center">
                                <div>
                                    <v-icon size="40" color="primary" class="ml-6"
                                        >mdi-human-child</v-icon
                                    >
                                </div>
                                <div style="flex-grow: 1">
                                    <v-card-title class="pb-0">
                                        <span
                                            class="text-h6 font-weight-bold text-primary ml-5"
                                        >
                                            {{ kindesdaten.vorname }}
                                            {{ kindesdaten.name }}

                                            <Tooltip
                                                top
                                                v-if="
                                                    kindesdaten.geschlecht ===
                                                    'MAENNLICH'
                                                "
                                            >
                                                <v-btn size="medium" icon>
                                                    <v-icon color="primary"
                                                        >mdi-gender-male</v-icon
                                                    >
                                                </v-btn>
                                                <template #content>
                                                    <span>männlich</span>
                                                </template>
                                            </Tooltip>
                                            <Tooltip
                                                top
                                                v-if="
                                                    kindesdaten.geschlecht ===
                                                    'WEIBLICH'
                                                "
                                            >
                                                <Button size="medium" icon>
                                                    <v-icon color="primary"
                                                        >mdi-gender-female</v-icon
                                                    ></Button
                                                >
                                                <template #content>
                                                    <span>weiblich</span>
                                                </template>
                                            </Tooltip>
                                            <Tooltip
                                                top
                                                v-if="
                                                    kindesdaten.geschlecht ===
                                                    'DIVERS'
                                                "
                                            >
                                                <Button size="medium" icon>
                                                    <v-icon color="primary"
                                                        >mdi-gender-transgender</v-icon
                                                    ></Button
                                                >
                                                <template #content>
                                                    <span>divers</span>
                                                </template>
                                            </Tooltip>
                                        </span>
                                    </v-card-title>

                                    <v-card-text class="pt-0">
                                        <div
                                            class="text-subtitle-2"
                                            style="color: gray"
                                        >
                                            <div
                                                class="ml-5 py-0"
                                                v-if="calculatedAge"
                                            >
                                                {{ calculatedAge.year }} Jahre
                                                und
                                                {{ calculatedAge.month }} Monate
                                                alt
                                            </div>
                                        </div>
                                    </v-card-text>
                                </div>
                            </div>
                            <v-divider class="mx-4"></v-divider>

                            <v-card-text class="mx-12 text-subtitle-1">
                                <div class="mb-2">
                                    <Tooltip top>
                                        <Button size="medium" icon>
                                            <v-icon
                                                class="mr-2"
                                                style="color: lightgray"
                                                >mdi-cake</v-icon
                                            >
                                        </Button>
                                        <template #content>
                                            <span
                                                >Geburtsdatum, Geburtsort</span
                                            >
                                        </template>
                                    </Tooltip>
                                    <span
                                        v-if="
                                            kindesdaten.geburtsdatum &&
                                            kindesdaten.geburtsdatum.trim() !==
                                                ''
                                        "
                                        style="color: black; font-weight: 500"
                                    >
                                        {{
                                            transformDate(kindesdaten.geburtsdatum)
                                        }} 
                                        <span
                                            v-if="
                                                kindesdaten.geburtsort &&
                                                kindesdaten.geburtsort.trim() !==
                                                    ''
                                            "
                                        >
                                            in {{ kindesdaten.geburtsort }}
                                        </span>
                                    </span>
                                    <span v-else>
                                        <span
                                            style="
                                                color: black;
                                                font-weight: 500;
                                            "
                                            >-</span
                                        >
                                    </span>
                                </div>

                                <div class="mb-2">
                                    <Tooltip top>
                                        <Button size="medium" icon>
                                            <v-icon
                                                class="mr-2"
                                                style="color: lightgray"
                                                >mdi-earth</v-icon
                                            >
                                        </Button>
                                        <template #content>
                                            <span>Staatsangehörigkeit</span>
                                        </template>
                                    </Tooltip>
                                    <span
                                        v-if="
                                            kindesdaten.staatsangehoerigkeit &&
                                            kindesdaten.staatsangehoerigkeit.trim() !==
                                                ''
                                        "
                                        style="color: black; font-weight: 500"
                                    >
                                        {{ kindesdaten.staatsangehoerigkeit }}
                                    </span>
                                    <span v-else>
                                        <span
                                            style="
                                                color: black;
                                                font-weight: 500;
                                            "
                                            >-</span
                                        >
                                    </span>
                                </div>

                                <div class="mb-2">
                                    <Tooltip top>
                                        <Button size="medium" icon>
                                            <v-icon
                                                class="mr-2"
                                                style="color: lightgray"
                                                >mdi-chat</v-icon
                                            >
                                        </Button>
                                        <template #content>
                                            <span>Sprache</span>
                                        </template>
                                    </Tooltip>
                                    <span
                                        v-if="
                                            kindesdaten.sprache &&
                                            kindesdaten.sprache.trim() !== ''
                                        "
                                        style="color: black; font-weight: 500"
                                    >
                                        {{ kindesdaten.sprache }}
                                    </span>
                                    <span v-else>
                                        <span
                                            style="
                                                color: black;
                                                font-weight: 500;
                                            "
                                            >-</span
                                        >
                                    </span>
                                </div>

                                <div class="mb-0">
                                    <Tooltip top>
                                        <Button size="medium" icon>
                                            <v-icon
                                                class="mr-2"
                                                style="color: lightgray"
                                                >mdi-home</v-icon
                                            >
                                        </Button>
                                        <template #content>
                                            <span>Wohnort</span>
                                        </template>
                                    </Tooltip>
                                    <span
                                        v-if="
                                            kindesdaten.wohnort &&
                                            kindesdaten.wohnort.trim() !== ''
                                        "
                                        style="color: black; font-weight: 500"
                                    >
                                        Wohnt bei {{ kindesdaten.wohnort }}
                                    </span>
                                    <span v-else>
                                        <span
                                            style="
                                                color: black;
                                                font-weight: 500;
                                            "
                                            >-</span
                                        >
                                    </span>
                                </div>
                            </v-card-text>

                            <v-card-text
                                class="mx-12"
                                style="color: gray"
                                v-if="
                                    (kindesdaten.bemerkungen &&
                                        kindesdaten.allergien !== '') ||
                                    (kindesdaten &&
                                        kindesdaten.allergien &&
                                        kindesdaten.allergien !== '') ||
                                    (kindesdaten.integrationsbedarf !== null &&
                                        kindesdaten.integrationsbedarf.length >
                                            0)
                                "
                            >
                                <div
                                    v-if="
                                        kindesdaten.bemerkungen &&
                                        kindesdaten.allergien !== ''
                                    "
                                    class="mb-2 text-subtitle-2"
                                >
                                    <Tooltip top>
                                        <Button size="medium" icon>
                                            <v-icon color="primary">
                                                mdi-information
                                            </v-icon>
                                        </Button>
                                        <template #content>
                                            <span>Bemerkungen vorhanden</span>
                                        </template>
                                    </Tooltip>
                                    <span class="ml-2">{{
                                        kindesdaten.bemerkungen
                                    }}</span>
                                </div>

                                <div
                                    class="mb-2 text-subtitle-2"
                                    v-if="
                                        kindesdaten &&
                                        kindesdaten.allergien &&
                                        kindesdaten !== null &&
                                        kindesdaten.allergien !== ''
                                    "
                                >
                                    <span size="medium" icon>
                                        <v-icon color="primary"> mdi-information </v-icon>
                                    </span>

                                    <span class="ml-2">{{
                                        kindesdaten.allergien
                                    }}</span>
                                </div>
                                <div
                                    class="text-subtitle-2"
                                    style="display: flex"
                                    v-if="
                                        kindesdaten.integrationsbedarf !==
                                            null &&
                                        kindesdaten.integrationsbedarf.length >
                                            0
                                    "
                                >
                                    <Tooltip top>
                                        <Button size="medium" icon>
                                            <v-icon color="primary">
                                                mdi-information
                                            </v-icon>
                                        </Button>
                                        <template #content>
                                            <span
                                                >Integrationsbedarf
                                                vorhanden</span
                                            >
                                        </template>
                                    </Tooltip>
                                    <div>
                                        <div
                                            v-for="(
                                                integrationsbedarf, index
                                            ) in kindesdaten.integrationsbedarf"
                                            class="ml-2"
                                            :key="`integrationsbedarf-${index}`"
                                        >
                                            {{ integrationsbedarf }}
                                        </div>
                                        <br />
                                    </div>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <!--  Zusatzinfos -->
                    <v-col cols="6" class="pr-6">
                        <v-card
                            style="width: 100%; border: none; box-shadow: none"
                        >
                            <v-card-text class="pt-0">
                                <div class="d-flex mb-2">
                                    <div
                                        class="text-subtitle-1 font-weight-bold text-grey-darken-2"
                                        style="flex: 1"
                                    >
                                        Bildungsinformationen
                                    </div>
                                </div>

                                <div
                                    v-for="(key, index) in [
                                        'bisherigeEinrichtung',
                                        'schulkind',
                                        'schule',
                                        'schulklasse',
                                    ]"
                                    :key="`${key}-${index}`"
                                >
                                    <div
                                        :class="{
                                            'd-flex': true,
                                            'mb-2': true,
                                            'even-row': index % 2 === 0,
                                        }"
                                    >
                                        <div
                                            class="text-subtitle-1 gray--text"
                                            style="flex: 1"
                                        >
                                            {{ formatKey(key) }}
                                        </div>
                                        <div
                                            class="text-subtitle-1 font-weight-bold"
                                            style="flex: 1; margin-left: 10px"
                                        >
                                            <template
                                                v-if="
                                                    kindesdaten[key] !== null &&
                                                    kindesdaten[key] !== ''
                                                "
                                            >
                                                <span
                                                    v-if="
                                                        typeof kindesdaten[
                                                            key
                                                        ] === 'boolean'
                                                    "
                                                >
                                                    <span
                                                        v-if="kindesdaten[key]"
                                                        >ja</span
                                                    >
                                                    <span v-else>nein</span>
                                                </span>
                                                <span v-else>{{
                                                    kindesdaten[key]
                                                }}</span>
                                            </template>
                                            <template v-else> - </template>
                                        </div>
                                    </div>
                                </div>

                                <div class="d-flex mb-2 mt-4">
                                    <div
                                        class="text-subtitle-1 font-weight-bold text-grey-darken-2"
                                        style="flex: 1"
                                    >
                                        Gesundheitsinformationen
                                    </div>
                                </div>
                                <div
                                    v-for="(key, index) in [
                                        'impfberatung',
                                        'vorsorgeuntersuchung',
                                    ]" 
                                    :key="index"
                                >
                                    <div
                                        :class="{
                                            'd-flex': true,
                                            'mb-2': true,
                                            'even-row': index % 2 === 0,
                                        }"
                                    >
                                        <div
                                            class="text-subtitle-1 gray--text"
                                            style="flex: 1"
                                        >
                                            {{ formatKey(key) }}
                                        </div>
                                        <div
                                            class="text-subtitle-1 font-weight-bold"
                                            style="flex: 1; margin-left: 10px"
                                        >
                                            <template
                                                v-if="
                                                    kindesdaten[key] !== null &&
                                                    kindesdaten[key] !== ''
                                                "
                                            >
                                                <span
                                                    v-if="
                                                        typeof kindesdaten[
                                                            key
                                                        ] === 'boolean'
                                                    "
                                                >
                                                    <span
                                                        v-if="kindesdaten[key]"
                                                        >ja</span
                                                    >
                                                    <span v-else>nein</span>
                                                </span>
                                                <span v-else>{{
                                                    kindesdaten[key]
                                                }}</span>
                                            </template>
                                            <template v-else> - </template>
                                        </div>
                                    </div>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <div v-if="geschwister !== null && geschwister !== []">
                    <v-divider class="mx-10 my-6"></v-divider>

                    <v-row class="mx-3">
                        <v-col cols="6">
                            <v-card
                                style="
                                    width: 100%;
                                    border: none;
                                    box-shadow: none;
                                "
                            >
                                <v-card-text class="text-subtitle-2">
                                    <div class="d-flex">
                                        <div
                                            class="text-subtitle-1 font-weight-bold text-grey-darken-2"
                                            style="flex: 1"
                                        >
                                            Geschwister
                                        </div>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-row no-gutters class="mb-6 mx-3 ml-6">
                        <v-col
                            v-for="(geschwist, index) in geschwister"
                            :key="`geschwister-${index}`"
                            cols="6"
                        >
                            <v-card
                                style="
                                    width: 100%;
                                    border: none;
                                    box-shadow: none;
                                "
                            >
                                <v-card-text class="pt-0 text-subtitle-2">
                                    <span
                                        class="text-subtitle-1 font-weight-bold text-primary"
                                    >
                                        {{ geschwist.vorname }}
                                        {{ geschwist.name }}
                                    </span>
                                    <div class="d-flex mb-1 mt-2 even-row">
                                        <div
                                            class="text-subtitle-1 gray--text"
                                            style="flex: 1"
                                        >
                                            Geburtsdatum
                                        </div>
                                        <div
                                            class="text-subtitle-1 font-weight-bold"
                                            style="flex: 1; margin-left: 10px"
                                        >
                                            <template
                                                v-if="geschwist.geburtsdatum"
                                            >
                                                <span>{{
                                                    transformDate(geschwist.geburtsdatum)
                                                }}</span>
                                            </template>
                                            <template v-else>
                                                <span>-</span>
                                            </template>
                                        </div>
                                    </div>
                                    <div class="d-flex mb-1">
                                        <div
                                            class="text-subtitle-1 gray--text"
                                            style="flex: 1"
                                        >
                                            Wohnort
                                        </div>
                                        <div
                                            class="text-subtitle-1 font-weight-bold"
                                            style="flex: 1; margin-left: 10px"
                                        >
                                            <template v-if="geschwist.wohnort">
                                                <span>{{
                                                    geschwist.wohnort
                                                }}</span>
                                            </template>
                                            <template v-else>
                                                <span>-</span>
                                            </template>
                                        </div>
                                    </div>
                                    <div class="d-flex mb-1 even-row">
                                        <div
                                            class="text-subtitle-1 gray--text"
                                            style="flex: 1"
                                        >
                                            Einrichtung
                                        </div>
                                        <div
                                            class="text-subtitle-1 font-weight-bold"
                                            style="flex: 1; margin-left: 10px"
                                        >
                                            <template
                                                v-if="geschwist.einrichtung"
                                            >
                                                <span>{{
                                                    geschwist.einrichtung
                                                }}</span>
                                            </template>
                                            <template v-else>
                                                <span>-</span>
                                            </template>
                                        </div>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </div>
            </v-expansion-panel-text>
        </v-expansion-panel>
    </v-expansion-panels>
</template>
<script>
import Tooltip from '@s/views/general_components/components/Tooltip';
import { transformDate } from '@s/util/helper.js';

export default {
    name: 'kindesdaten',
    components: {
        Tooltip,
    },
    data() {
        return {
            show: true,
        };
    },
    methods: {
        transformDate,
        formatKey(key) {
            switch (key) {
                case 'bisherigeEinrichtung':
                    return 'Bisherige Einrichtung';
                case 'schulkind':
                    return 'Schulkind';
                case 'schule':
                    return 'Schule';
                case 'schulklasse':
                    return 'Schulklasse';
                case 'impfberatung':
                    return 'Impfberatung';
                case 'vorsorgeuntersuchung':
                    return 'Vorsorgeuntersuchung';

                default:
                    return key;
            }
        },
    },
    props: {
        kindesdaten: {
            type: Object,
            required: true,
        },
        geschwister: {
            type: Array,
            default: () => [],
        },

        calculatedAge: {
            type: Object,
            default: null,
        },
    },

    mounted() {
    },
};
</script>

<style lang="stylus" scoped>
i
  font-weight: bold


.even-row
  background-color: #f0f0f0
</style>
