export default [
    {
      title: 'Aktionen',
      align: 'left',
      sortable: false,
      key: null
    },
    {
      title: 'Zugeteilt an',
      align: 'center',
      sortable: true,
      key: 'zugeteilteEinrichtung.value' 
    },
    {
      title: 'Status',
      align: 'center',
      sortable: true,
      key: 'status.value' 
    },
    {
      title: 'Name',
      align: 'center',
      sortable: true,
      key: 'name[0].sortVal' 
    },
    {
      title: 'Geburtsdatum',
      align: 'center',
      sortable: true,
      key: 'birthdateSeconds' 
    },
    {
      title: 'Wohnort',
      align: 'center',
      sortable: true,
      key: 'wohnort[0].value' 
    },
    {
      title: 'Aufnahmedatum',
      align: 'center',
      sortable: true,
      key: 'aufnahmedatumSeconds' 
    },
    {
      title: 'Merkmale',
      align: 'center',
      sortable: true,
      key: 'iStatus.length' 
    },
    {
      title: ' ',
      align: '',
      sortable: false,
      key: null 
    }
  ];
  