import { createApp } from 'vue';
import App from './Application.vue'; 
import router from './views';

//Vuetify
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi'

import { de } from 'vuetify/locale';

import './styles/index.styl';

import Revue from './util/revue';
import { actions as reduxActions } from './reducer/';

const vuetify = createVuetify({
    components,
    directives,
    theme: {
        themes: {
            light: {
                colors: {
                    primary: '#00838F',
                    'primary-lighten-1': '#359da9',
                    'primary-lighten-2': '#55b8c5',
                    secondary: '#90A4AE',
                    accent: '#00BCD4',
                    success: '#009688',
                    error: '#EF5350',
                    'error-darken-2': '#ad2218',
                },
            },
        },
    },
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
          mdi,
        },
    },
    locale: {
        locale: 'de',
        fallbackLocale: 'en', 
        messages: {
          de: de, 
        },
      },
});

export function setupStore(app, reduxStore) {
    return new Revue(app, reduxStore, reduxActions); 
}


class AppRunner {
    constructor(reduxStore) {
        const app = createApp(App);
        this.store = setupStore(app, reduxStore);
        app.use(vuetify);

        router.beforeEach((to, from, next) => {
                const authTrustLevel = this.store.state.auth.trustlevel;
                if (to.path !== '/' && authTrustLevel !== 1) {
                    next('/');
                } else {
                    next();
                }
        });

        app.use(router);

        this.store.dispatch(
            reduxActions.user.info(() => {
                setInterval(() => {
                    this.store.dispatch(reduxActions.user.checkSession());
                }, 1500000); 
            })
        );

        app.mount('#app');
    }
}

export default (reduxStore) => {
    new AppRunner(reduxStore);
};
